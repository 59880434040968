import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, message, Modal, Table } from "antd";
import AddEmirate from "common/AddEmirate";
import EditEmirate from "common/EditEmirate";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import PrivateAxios from "services/axiosService";

import styles from "./Emirates.module.scss";

function Emirates() {
  const [list, setList] = useState([]);
  const [addEmirate, setEmirate] = useState(false);
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Delivery</Breadcrumb.Item>
        <Breadcrumb.Item>Emirates</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Emirates</h3>
    </div>
  );

  const RightComponent = () => (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      size="large"
      className={styles.btn}
      onClick={() => setEmirate(true)}
      disabled
    >
      Add Emirate
    </Button>
  );

  const getEmirates = () => {
    PrivateAxios.get("/api/emirates")
      .then(function (response) {
        const data = response?.data?.data?.map((item, i) => ({
          key: i + 1,
          name: item?.name,
          _id: item?._id,
        }));
        setList(data);
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  useEffect(() => {
    getEmirates();
  }, []);

  const [deleting, setDelete] = useState(false);
  const handleDelete = (data) => {
    Modal.confirm({
      title: "Delete",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete "${data?.name}" ?`,
      okText: "Sure",
      cancelText: "Cancel",
      onOk: () => {
        setDelete(true);

        PrivateAxios.delete(`/api/emirates/${data?._id}`)
          .then(function (response) {
            message.success(response?.data?.message || "Something went wrong!");
            getEmirates();
            setDelete(false);
          })
          .catch(function (error) {
            setDelete(false);
            message.error(
              error?.response?.data?.message || "Something went wrong!"
            );
          });
      },
    });
  };

  const [edit, setEdit] = useState(null);

  const columns = [
    {
      title: "SL No.",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Edit",
      key: "edit",
      render: (data) => (
        <Button
          onClick={() => setEdit(data)}
          className="d-flex align-items-center"
          disabled
          icon={<EditOutlined />}
        >
          Edit
        </Button>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      render: (data) => (
        <Button
          onClick={() => handleDelete(data)}
          className="d-flex align-items-center"
          icon={<DeleteOutlined />}
          type="primary"
          disabled
          loading={deleting}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div className={styles.EmiratesWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className="mt-3">
        <Table
          dataSource={list}
          pagination={list?.length > 10}
          columns={columns}
        />
      </div>
      <AddEmirate
        fetch={getEmirates}
        addEmirate={addEmirate}
        setEmirate={setEmirate}
      />
      <EditEmirate
        fetch={getEmirates}
        addEmirate={edit !== null}
        setEmirate={setEdit}
        data={edit}
      />
    </div>
  );
}

export default Emirates;
