import styles from "./ForgotPassword.module.scss";

function ForgotPassword() {
  return (
    <div className={styles.ForgotPasswordWrapper}>
      <h1>ForgotPassword</h1>
    </div>
  );
}

export default ForgotPassword;
