// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Orders_OrdersWrapper__Bs7lM thead[class*=ant-table-thead] th {\n  background-color: #fff !important;\n}\n.Orders_OrdersWrapper__Bs7lM .Orders_heading___RN_o {\n  color: #ae674c;\n}\n.Orders_OrdersWrapper__Bs7lM .Orders_btn__1iH_F {\n  background-color: #d02130;\n  border-color: #d02130;\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n}\n.Orders_OrdersWrapper__Bs7lM .Orders_key__3R6zS {\n  font-size: 12px;\n  margin-bottom: 0;\n}", "",{"version":3,"sources":["webpack://src/pages/Orders/Orders.module.scss"],"names":[],"mappings":"AACE;EACE,iCAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EACE,yBAAA;EACA,qBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AAFJ;AAKE;EACE,eAAA;EACA,gBAAA;AAHJ","sourcesContent":[".OrdersWrapper {\n  thead[class*=\"ant-table-thead\"] th {\n    background-color: #fff !important;\n  }\n\n  .heading {\n    color: #ae674c;\n  }\n\n  .btn {\n    background-color: #d02130;\n    border-color: #d02130;\n    border-radius: 5px;\n    display: flex;\n    align-items: center;\n  }\n\n  .key {\n    font-size: 12px;\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OrdersWrapper": "Orders_OrdersWrapper__Bs7lM",
	"heading": "Orders_heading___RN_o",
	"btn": "Orders_btn__1iH_F",
	"key": "Orders_key__3R6zS"
};
export default ___CSS_LOADER_EXPORT___;
