// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrintInvoice_PrintInvoiceWrapper__3XA_E {\n  padding: 5%;\n}\n.PrintInvoice_PrintInvoiceWrapper__3XA_E .PrintInvoice_prodImg__1p-Bv {\n  height: 52px;\n  width: 52px;\n}\n.PrintInvoice_PrintInvoiceWrapper__3XA_E .PrintInvoice_prodName__1EyUp {\n  margin-bottom: 0;\n  font-size: 13px;\n  color: #67666b;\n}\n.PrintInvoice_PrintInvoiceWrapper__3XA_E .PrintInvoice_prodPrice__3mE4D {\n  margin-bottom: 0;\n  font-size: 11px;\n  color: #d02130;\n}", "",{"version":3,"sources":["webpack://src/common/PrintInvoice/PrintInvoice.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AACJ;AACE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AACJ","sourcesContent":[".PrintInvoiceWrapper {\n  padding: 5%;\n\n  .prodImg {\n    height: 52px;\n    width: 52px;\n  }\n  .prodName {\n    margin-bottom: 0;\n    font-size: 13px;\n    color: #67666b;\n  }\n  .prodPrice {\n    margin-bottom: 0;\n    font-size: 11px;\n    color: #d02130;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PrintInvoiceWrapper": "PrintInvoice_PrintInvoiceWrapper__3XA_E",
	"prodImg": "PrintInvoice_prodImg__1p-Bv",
	"prodName": "PrintInvoice_prodName__1EyUp",
	"prodPrice": "PrintInvoice_prodPrice__3mE4D"
};
export default ___CSS_LOADER_EXPORT___;
