// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Invoice_InvoiceWrapper__3N4tF .Invoice_heading__29HmI {\n  color: #ae674c;\n}\n.Invoice_InvoiceWrapper__3N4tF thead[class*=ant-table-thead] th {\n  background-color: #fff !important;\n  font-weight: 700;\n  font-family: \"Roboto\";\n}\n.Invoice_InvoiceWrapper__3N4tF p {\n  font-size: 15px;\n  margin-bottom: 1px;\n}\n.Invoice_InvoiceWrapper__3N4tF .Invoice_footer__2pll7 p {\n  text-align: right;\n}\n.Invoice_InvoiceWrapper__3N4tF .Invoice_footer__2pll7 .Invoice_bold__2cMZ5 {\n  text-align: right;\n  font-weight: 800;\n}\n.Invoice_InvoiceWrapper__3N4tF tbody[class*=ant-table-tbody] tr td {\n  border: 0px solid #fff !important;\n}", "",{"version":3,"sources":["webpack://src/pages/Invoice/Invoice.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAEE;EACE,iCAAA;EACA,gBAAA;EACA,qBAAA;AAAJ;AAGE;EACE,eAAA;EACA,kBAAA;AADJ;AAKI;EACE,iBAAA;AAHN;AAKI;EACE,iBAAA;EACA,gBAAA;AAHN;AAME;EACE,iCAAA;AAJJ","sourcesContent":[".InvoiceWrapper {\n  .heading {\n    color: #ae674c;\n  }\n  thead[class*=\"ant-table-thead\"] th {\n    background-color: #fff !important;\n    font-weight: 700;\n    font-family: \"Roboto\";\n  }\n\n  p {\n    font-size: 15px;\n    margin-bottom: 1px;\n  }\n\n  .footer {\n    p {\n      text-align: right;\n    }\n    .bold {\n      text-align: right;\n      font-weight: 800;\n    }\n  }\n  tbody[class*=\"ant-table-tbody\"] tr td {\n    border: 0px solid #fff !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InvoiceWrapper": "Invoice_InvoiceWrapper__3N4tF",
	"heading": "Invoice_heading__29HmI",
	"footer": "Invoice_footer__2pll7",
	"bold": "Invoice_bold__2cMZ5"
};
export default ___CSS_LOADER_EXPORT___;
