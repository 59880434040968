import "./App.scss";

import PrivateRoute from "common/PrivateRoute";
import PublicRoute from "common/PublicRoute";
import ScrollToTop from "common/ScrollToTop";
import AddCategory from "pages/AddCategory";
import AddHomeManagement from "pages/AddHomeManagement";
import AddOffer from "pages/AddOffer";
import AddProduct from "pages/AddProduct";
import BulkUploadProducts from "pages/BulkUploadProducts";
import CancelledOrders from "pages/CancelledOrders";
import Category from "pages/Category";
import DeliveredOrders from "pages/DeliveredOrders";
import EditOffer from "pages/EditOffer";
// import Dashboard from "pages/Dashboard";
import EditProduct from "pages/EditProduct";
import Emirates from "pages/Emirates";
import ForgotPassword from "pages/ForgotPassword";
import HomeManagement from "pages/HomeManagement";
import Invoice from "pages/Invoice";
import Login from "pages/Login";
import ManageBuyers from "pages/ManageBuyers";
import ManageStaff from "pages/ManageStaff";
import NewOrders from "pages/NewOrders";
// import NewSale from "pages/NewSale";
import NotFound from "pages/NotFound";
import Offer from "pages/Offer";
import OrderDetail from "pages/OrderDetail";
import Orders from "pages/Orders";
import ProcessedOrders from "pages/ProcessedOrders";
// import PackedOrders from "pages/PackedOrders";
import Product from "pages/Product";
import ProductDetail from "pages/ProductDetail";
import Production from "pages/Production";
// import RawMaterial from "pages/RawMaterial";
import Register from "pages/Register";
import Sales from "pages/Sales";
import Settings from "pages/Settings";
// import ShippedOrders from "pages/ShippedOrders";
import Subcategory from "pages/Subcategory";
// import SuspendedSales from "pages/SuspendedSales";
import UserDetails from "pages/UserDetails";
import { BrowserRouter, Switch } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <PublicRoute restricted={false} component={Login} path="/login" exact />
        <PublicRoute
          restricted={false}
          component={ForgotPassword}
          path="/forgot-password"
          exact
        />
        <PublicRoute
          restricted={false}
          component={Register}
          path="/sign-up"
          exact
        />
        <PrivateRoute component={Orders} path="/" exact />
        <PrivateRoute component={NewOrders} path="/new-orders" exact />
        {/* <PrivateRoute component={PackedOrders} path="/packed-orders" exact />
            <PrivateRoute component={ShippedOrders} path="/shipped-orders" exact /> */}
        <PrivateRoute
          component={ProcessedOrders}
          path="/processing-orders"
          exact
        />
        <PrivateRoute
          component={DeliveredOrders}
          path="/delivered-orders"
          exact
        />
        <PrivateRoute
          component={CancelledOrders}
          path="/cancelled-orders"
          exact
        />
        <PrivateRoute component={Category} path="/category" exact />
        <PrivateRoute component={AddCategory} path="/add-category" exact />
        <PrivateRoute component={Offer} path="/offers" exact />
        <PrivateRoute component={AddOffer} path="/add-offers" exact />
        <PrivateRoute component={EditOffer} path="/edit-offers" exact />
        <PrivateRoute component={Subcategory} path="/subcategory" exact />
        <PrivateRoute component={ProductDetail} path="/product-detail" exact />
        <PrivateRoute component={Product} path="/products" exact />
        <PrivateRoute component={AddProduct} path="/add-products" exact />
        <PrivateRoute
          component={BulkUploadProducts}
          path="/bulk-upload"
          exact
        />
        <PrivateRoute component={EditProduct} path="/edit-products" exact />
        <PrivateRoute component={Sales} path="/sales" exact />
        <PrivateRoute component={Invoice} path="/invoice" exact />
        <PrivateRoute component={ManageBuyers} path="/users" exact />
        <PrivateRoute component={ManageStaff} path="/staffs" exact />
        <PrivateRoute component={UserDetails} path="/users/:id" />
        <PrivateRoute component={Production} path="/production" exact />
        <PrivateRoute component={Emirates} path="/emirates" exact />
        <PrivateRoute component={Orders} path="/orders" exact />
        <PrivateRoute component={OrderDetail} path="/order/:id" exact />
        <PrivateRoute component={Settings} path="/settings" exact />
        <PrivateRoute
          component={HomeManagement}
          path="/home-management"
          exact
        />
        <PrivateRoute
          component={AddHomeManagement}
          path="/home-management/add-banner"
          exact
        />
        <PublicRoute component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
