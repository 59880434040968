import { AutoComplete, Button, DatePicker, Input, message, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import { token } from "utils";
import { BASE_URL } from "utils/constants";
import * as Yup from "yup";

import styles from "./AddProductionModal.module.scss";

function EditProductionModal({ show, data, setShow, refetch }) {
  const ProductionSchema = Yup.object().shape({
    name: Yup.string().required("Enter product name"),
    date: Yup.string().required("Choose a date "),
    qty: Yup.string().required("Enter a valid quantity"),
  });
  const dateFormat = "YYYY-MM-DD";
  function onChange(date, setFieldValue) {
    const formated = moment(date).format(dateFormat);
    setFieldValue("date", formated);
  }

  const handleSearch = (e) => {
    if (e.length > 0) {
      const config = {
        method: "get",
        url: `${BASE_URL}/api/search-product?q=${e}`,
        headers: {
          "x-auth-token": token(),
        },
      };
      axios(config)
        .then(function (response) {
          setSearch(response?.data?.data);
        })
        .catch(function (error) {
          message.error(
            error?.response?.data?.message || "Something went wrong!"
          );
        });
    }
  };
  const [result, setSearch] = useState([
    {
      name: data?.product?.name,
      _id: data?.product?._id,
    },
  ]);

  const options = result?.map((item) => ({
    key: item?._id,
    value: item?.name,
  }));

  const [option, setOption] = useState(data?.product?._id);
  const [loading, setLoading] = useState(false);

  const handleSelect = (e, selected, setField) => {
    setOption(selected?.key);
    setField("name", e);
  };

  return (
    <div className={styles.AddProductionModalWrapper}>
      <Modal
        title="Edit Production"
        visible={show}
        onCancel={() => setShow(null)}
        okText="Save"
        cancelText="Cancel"
        footer={null}
        destroyOnClose
      >
        <Formik
          initialValues={{
            name: data?.product?.name,
            date: moment(data?.date).format(dateFormat),
            qty: data?.qty,
          }}
          validationSchema={ProductionSchema}
          onSubmit={(values) => {
            setLoading(true);
            const data1 = JSON.stringify({
              date: values?.date,
              pid: option,
              qty: values.qty,
            });

            const config = {
              method: "patch",
              url: `${BASE_URL}/api/production-sheets/${data?._id}`,
              headers: {
                "x-auth-token": token(),
                "Content-Type": "application/json",
              },
              data: data1,
            };

            axios(config)
              .then(function (response) {
                setLoading(false);
                refetch();
                message.success(response?.data?.message);
                setOption(null);
                setSearch([]);
                setShow(null);
              })
              .catch(function (error) {
                setLoading(false);

                message.error(
                  error?.response?.data?.message || "Something went wrong!"
                );
              });
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            values,
          }) => (
            <div>
              <p className="mb-0">
                Product Name<span className="text-danger">*</span>
              </p>
              <Input.Group compact>
                <AutoComplete
                  className="w-100"
                  placeholder="Search Product"
                  onChange={(e) => handleSearch(e, setFieldValue)}
                  options={options || []}
                  onBlur={handleBlur("name")}
                  onSelect={(e, value) => handleSelect(e, value, setFieldValue)}
                  value={values.name}
                  disabled
                />
              </Input.Group>
              {errors.name && touched.name ? (
                <pre className="text-danger">{errors?.name}</pre>
              ) : null}
              <p className="mb-0 mt-3">
                Date<span className="text-danger">*</span>
              </p>
              <DatePicker
                format={dateFormat}
                onChange={(e) => onChange(e, setFieldValue)}
                className="w-100"
                placeholder="Choose Date"
                onBlur={handleBlur("date")}
                value={moment(values.date)}
                disabled
              />{" "}
              {errors.date && touched.date ? (
                <pre className="text-danger">{errors?.date}</pre>
              ) : null}
              <p className="mb-0 mt-3">
                Quantity<span className="text-danger">*</span>
              </p>
              <Input
                onChange={handleChange("qty")}
                onBlur={handleBlur("qty")}
                placeholder="Enter Quantity"
                value={values.qty}
                type="number"
              />
              {errors.qty && touched.qty ? (
                <pre className="text-danger">{errors?.qty}</pre>
              ) : null}
              <div className="d-flex mt-3 w-100 align-items-center justify-content-center">
                <Space>
                  <Button onClick={() => setShow(null)} disabled={loading}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    loading={loading}
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </div>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default EditProductionModal;
