// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManageStaff_ManageStaffWrapper__3lEPc .ManageStaff_heading__KIU_G {\n  color: #ae674c;\n}\n.ManageStaff_ManageStaffWrapper__3lEPc thead[class*=ant-table-thead] th {\n  background-color: #fff !important;\n  font-weight: 700;\n  font-family: \"Roboto\";\n}", "",{"version":3,"sources":["webpack://src/pages/ManageStaff/ManageStaff.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAEE;EACE,iCAAA;EACA,gBAAA;EACA,qBAAA;AAAJ","sourcesContent":[".ManageStaffWrapper {\n  .heading {\n    color: #ae674c;\n  }\n  thead[class*=\"ant-table-thead\"] th {\n    background-color: #fff !important;\n    font-weight: 700;\n    font-family: \"Roboto\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ManageStaffWrapper": "ManageStaff_ManageStaffWrapper__3lEPc",
	"heading": "ManageStaff_heading__KIU_G"
};
export default ___CSS_LOADER_EXPORT___;
