import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  EditFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Divider, message, Popconfirm } from "antd";
import { Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import EditCategory from "common/EditCategory";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PrivateAxios from "services/axiosService";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./Category.module.scss";

function Category() {
  const [isModalVisible, setIsModalVisible] = useState(null);

  const showModal = (data) => {
    setIsModalVisible(data);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (src) => (
        <img src={src} alt="thumbnail" className={styles.productImg} />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Displayed",
      key: "active",
      render: (bool) => (
        <Space size="middle">
          {bool?.active ? (
            <CheckOutlined className="text-success" />
          ) : (
            <CloseOutlined className="text-danger" />
          )}
        </Space>
      ),
    },
    // {
    //   title: "Position",
    //   key: "index",
    //   render: ({ index }) => (
    //     <Space size="middle" onClick={(e) => e?.stopPropagation()}>
    //       <InputNumber
    //         value={index}
    //         onClick={(e) => e?.stopPropagation()}
    //         onChange={(e) => {
    //           console.log(`e`, e);
    //         }}
    //       />
    //     </Space>
    //   ),
    // },
    {
      title: "Edit",
      key: "edit",
      render: (data) => (
        <Space size="middle">
          <EditFilled
            onClick={(e) => {
              e?.stopPropagation();
              showModal(data);
            }}
          />
        </Space>
      ),
    },

    {
      title: "Delete",
      key: "delete",
      render: (item) => (
        <Space size="middle" onClick={(e) => e?.stopPropagation()}>
          <Popconfirm
            onClick={(e) => e?.stopPropagation()}
            title="Are you sure to delete this Category?"
            onConfirm={() => {
              handleDelete(item?.id);
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <DeleteFilled
              className="text-danger"
              onClick={(e) => e?.stopPropagation()}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Catalog</Breadcrumb.Item>
        <Breadcrumb.Item>Categories</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Categories</h3>
    </div>
  );

  useEffect(() => {
    fetchCategory();
  }, []);

  const [loading, setLoading] = useState(true);
  const [result, setresult] = useState([]);

  const fetchCategory = () => {
    let config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get("/api/categories", config)
      .then(function (response) {
        setLoading(false);
        setresult(response?.data);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };
  const RightComponent = () => (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      size="large"
      className={styles.btn}
      onClick={() => history.push("/add-category", { data: result?.data })}
    >
      Add Category
    </Button>
  );
  const history = useHistory();

  const getChildren = (children) => {
    const out = children?.map((child, i) => ({
      index: i + 1,
      key: child?._id,
      id: child?._id,
      name: child?.name,
      description: child?.description,
      active: child?.isdisplayed,
      image: BASE_URL + "/" + child?.images?.thumbnail,
      children:
        child?.subcategories?.length > 0
          ? getChildren(child?.subcategories)
          : [],
      ...child,
    }));
    return out;
  };

  const data = result?.data?.map((item, i) => ({
    index: i + 1,
    key: item?._id,
    id: item?._id,
    name: item?.name,
    description: item?.description,
    active: item?.isdisplayed,
    image: BASE_URL + "/" + item?.images?.thumbnail,
    children:
      item?.subcategories?.length > 0 ? getChildren(item?.subcategories) : [],
    ...item,
  }));

  const handleDelete = (id) => {
    setLoading(true);
    let config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.delete(`/api/categories/${id}`, config)
      .then(function (response) {
        setLoading(false);
        message.success(response?.data?.message);
        fetchCategory();
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <div className={styles.CategoryWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className={styles.content}>
        <div>
          <h6 className="mb-2  text-success">
            Categories({data?.length || 0})
          </h6>
        </div>
        <Divider className="my-2" />
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          onRow={(record) => {
            return {
              onClick: () =>
                history.push("/subcategory", {
                  record: record,
                  categoryArr: result?.data,
                }), // click row
            };
          }}
        />
      </div>
      <Modal
        footer={null}
        title="Edit Category"
        visible={isModalVisible !== null}
        setIsModalVisible={setIsModalVisible}
        onCancel={() => setIsModalVisible(null)}
        destroyOnClose={true}
        width={"80%"}
      >
        <EditCategory
          data={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          fetchCategory={fetchCategory}
          categoryArr={result?.data}
        />
      </Modal>
    </div>
  );
}

export default Category;
