import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteFilled,
  EditFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  // Input,
  message,
  Popconfirm,
  Row,
  Table,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import PrivateAxios from "services/axiosService";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./HomeManagement.module.scss";

function HomeManagement({ history }) {
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Banner</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Banners</h3>
    </div>
  );

  const RightComponent = () => <div />;
  const column = [
    {
      title: "ID",
      dataIndex: "id",
      key: "key",
    },
    {
      title: "Web Image",
      // dataIndex: "web",
      key: "web",
      render: ({ web }) => (
        <Avatar size="large" shape="square" src={BASE_URL + "/" + web} />
      ),
    },
    {
      title: "Mobile",
      // dataIndex: "mobile",
      key: "mobile",
      render: ({ mobile }) => (
        <Avatar shape="square" size="large" src={BASE_URL + "/" + mobile} />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Link",
      key: "link",
      render: ({ link }) => <a href={link}>Link</a>,
    },
    // {
    //   title: "Position",
    //   key: "position",
    //   render: () => <Input type="number" />,
    //   width: 50,
    // },
    {
      title: "Displayed",
      key: "status",
      render: ({ status }) =>
        status ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
      align: "center",
    },
    {
      title: "Edit",
      key: "edit",
      render: (data) => (
        <Button
          icon={<EditFilled />}
          onClick={() => history.push("/home-management/add-banner", data)}
        />
      ),
      align: "center",
    },
    {
      title: "Delete",
      key: "key",
      render: ({ key }) => (
        <Popconfirm
          title="Are you sure to delete this banner?"
          onConfirm={() => {
            handleDelete(key);
          }}
          onCancel={(e) => {
            e.stopPropagation();
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button icon={<DeleteFilled />} />
        </Popconfirm>
      ),

      align: "center",
    },
  ];

  const handleDelete = (id) => {
    const config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.delete(`/api/banners/${id}`, config)
      .then(function (response) {
        getBanners();
        message.success(response?.data?.message);
      })
      .catch(function (error) {
        message.error(error?.response?.data?.message);
      });
  };

  const [result, setResult] = useState(null);
  const getBanners = () => {
    const config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get("/api/banners", config)
      .then(function (response) {
        setResult(response?.data?.data);
      })
      .catch(function (error) {
        message.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getBanners();
  }, []);

  const dataSource = result?.map((item, i) => ({
    id: i + 1,
    key: item?._id,
    web: item?.images?.web,
    mobile: item?.images?.mobile,
    ...item,
  }));

  return (
    <div className={styles.HomeManagementWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className={styles.content}>
        <Row justify="space-between" align="middle">
          <Col>
            <p className={styles.subHead}>
              <strong>Home Banner</strong>
            </p>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              size="large"
              className={styles.btn}
              onClick={() => history.push("/home-management/add-banner")}
            >
              Add Banner
            </Button>
          </Col>
        </Row>
        <Divider className="mt-2 mb-1" />
        <Table
          dataSource={dataSource}
          columns={column}
          pagination={dataSource?.length > 10}
          loading={result === null}
        />
      </div>
    </div>
  );
}

export default HomeManagement;
