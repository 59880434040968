/* eslint-disable react/prop-types */
import { Breadcrumb, message, Table } from "antd";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import PrivateAxios from "services/axiosService";
import { token } from "utils";

import styles from "./ManageBuyers.module.scss";

function ManageBuyers() {
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Manage Customers</Breadcrumb.Item>
        <Breadcrumb.Item>All Customers</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Customers</h3>
    </div>
  );
  const RightComponent = () => <div />;

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const listUsers = () => {
    setLoading(true);
    const config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get("/api/users", config)
      .then(function (response) {
        setLoading(false);
        setData(response?.data?.data?.reverse());
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    listUsers();
  }, []);

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Phone Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  return (
    <div className={styles.ManageBuyersWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />

      <div className="mt-3">
        <Table
          className="mt-3"
          dataSource={data}
          columns={columns}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default ManageBuyers;
