/* eslint-disable no-unused-vars */
import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import EditProduct from "common/EditProduct";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PrivateAxios from "services/axiosService";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./Product.module.scss";

function Product() {
  const [isLoading, setLoading] = useState(true);
  const [result, setResult] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(null);
  const { Search } = Input;
  const [searchText, setSearchText] = useState("");
  const history = useHistory();

  //fetch all products
  const fetchProducts = () => {
    setLoading(true);
    const config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get("/api/products?page=1&size=20", config)
      .then(function (response) {
        console.log(response.data.data);
        setResult(response?.data?.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  //columns for antd product table
  const columns = [
    {
      title: "Code",
      dataIndex: "pcode",
      key: "_id",
      render: (code) => (code ? code : "-"),
      width: 150,
      align: "center",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (src) => (
        <img src={src} alt="prod-img" className={styles.productImg} />
      ),
      width: 100,
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Category",
      key: "category",
      width: 200,
      render: ({ category }) => {
        return category?.map((item) => <Tag key={item?._id}>{item?.name}</Tag>);
      },
    },
    {
      title: "Price",
      key: "price",
      render: (data) => <span>{`AED ${data?.price?.toFixed(2)}`}</span>,
      price: 200,
    },

    {
      title: "Status",
      key: "stock",
      render: (text) => {
        return text.status ? (
          <Tag color="#87d068">Visible</Tag>
        ) : (
          <Tag color="#f50">In Visible</Tag>
        );
      },
      width: 50,
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <Space size="middle">
          <EditFilled
            onClick={(e) => {
              e.stopPropagation();
              history.push("edit-products", item);
            }}
          />
          <Popconfirm
            title="Are you sure to delete this product?"
            onConfirm={(e) => {
              e.stopPropagation();
              handleDelete(item?._id);
            }}
            onCancel={(e) => {
              e.stopPropagation();
            }}
            okText="Yes"
            cancelText="No"
          >
            <DeleteFilled
              className="text-danger"
              onClick={(e) => e.stopPropagation()}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  //datasource for product table
  const dataSource = result?.map((item, i) => ({
    key: item?._id,
    id: i + 1,
    pcode: item?.code,
    name: item?.name,
    image: BASE_URL + "/" + item?.images?.mainimage,
    category: item?.categories,
    price: item?.price?.toFixed(2),
    description: item?.description,
    shortDescription: item?.smalldescription,
    status: item?.status,
    unit: item?.unit,
    _id: item?._id,
    imageArr: item?.images?.gallery,
    ...item,
  }));

  //delete product
  const handleDelete = (id) => {
    let config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.delete(`/api/products/${id}`, config)
      .then(function (response) {
        fetchProducts();
        setLoading(false);
        message.success(response?.data?.message);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  //search product by name
  const handleSearch = (text) => {
    if (text?.length === 0) {
      return;
    }
    setLoading(true);
    const config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get(`/api/search-product?q=${text}`, config)
      .then(function (response) {
        setLoading(false);
        setResult(response?.data?.data);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Catalog</Breadcrumb.Item>
        <Breadcrumb.Item>Products</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Products</h3>
    </div>
  );
  const RightComponent = () => (
    <Row>
      <Space>
        <Button
          type="secondary"
          icon={<UploadOutlined />}
          size="large"
          className={styles.btn2}
          onClick={() => history.push("/bulk-upload")}
        >
          Bulk Upload
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="large"
          className={styles.btn}
          onClick={() => history.push("/add-products")}
        >
          Add Products
        </Button>
      </Space>
    </Row>
  );

  return (
    <div className={styles.ProductWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className="my-3 w-50 d-flex align-items-center">
        <Search
          className="me-3"
          placeholder="Search product name"
          allowClear
          enterButton="Search"
          size="large"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          onSearch={handleSearch}
        />
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setSearchText("");
            fetchProducts();
          }}
        >
          Reset
        </Button>
      </div>
      <div className="my-4">
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={dataSource?.length > 20}
          loading={isLoading}
          onRow={(record) => {
            return {
              onClick: () => history.push("product-detail", { record }), // click row
            };
          }}
        />
      </div>
      <Modal
        footer={null}
        title="Edit Product"
        visible={isModalVisible !== null}
        setIsModalVisible={setIsModalVisible}
        onCancel={() => setIsModalVisible(null)}
        isModalVisible={isModalVisible}
        destroyOnClose={true}
        width="1000px"
      >
        <EditProduct
          detail={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          fetchProducts={fetchProducts}
        />
      </Modal>
    </div>
  );
}

export default Product;
