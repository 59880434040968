import { Col, Divider, Row, Table } from "antd";
import React from "react";

import styles from "./PrintInvoice.module.scss";

const PrintInvoice = React.forwardRef((props, ref) => {
  const { data, tableData } = props;
  const columns = [
    {
      title: "Products",
      key: "name",
      render: (data) => <p className={styles.prodName}>{data?.name}</p>,
    },
    {
      title: "Price",
      key: "price",
      render: (data) => (
        <span className={styles.prodPrice}>AED {data?.price?.toFixed(2)}</span>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Total",
      key: "total",
      render: (data) => <span>AED {data?.total?.toFixed(2)}</span>,
    },
  ];
  return (
    <div className={styles.PrintInvoiceWrapper} ref={ref} props={props}>
      <h4>Order Details</h4>
      <Row align="middle">
        <Col>
          <p className="mb-0">Ordered on {data?.time} </p>
        </Col>
        <Col>
          <Divider type="vertical" />
        </Col>
        <Col>
          <p className="mb-0"> Order# {data?.oid} </p>
        </Col>
      </Row>
      <Row className="p-3 border rounded my-3" gutter={10}>
        <Col span={8}>
          <h6>Shipping Address</h6>
          <p>
            {data?.addresses?.delivery?.fullname}
            <br />
            {data?.addresses?.delivery?.address} <br />{" "}
            {data?.addresses?.delivery?.building} <br />
            {data?.addresses?.delivery?.locality}
            <br />
            {data?.addresses?.delivery?.landmark}
            <br />
            {data?.addresses?.delivery?.emirates},
            {data?.addresses?.delivery?.phonenumber}
          </p>
        </Col>
        <Col span={8}>
          <h6>Billing Address</h6>
          <p>
            {data?.addresses?.billing?.fullname}
            <br />
            {data?.addresses?.billing?.address} <br />{" "}
            {data?.addresses?.billing?.building} <br />
            {data?.addresses?.billing?.locality}
            <br />
            {data?.addresses?.billing?.landmark}
            <br />
            {data?.addresses?.billing?.emirates},
            {data?.addresses?.billing?.phonenumber}
          </p>
        </Col>
        <Col span={8}>
          <h6>Order Summary</h6>
          <Row align="middle" justify="space-between">
            <Col>Payment Method:</Col>
            <Col>{data?.paymenttype === "cod" ? "COD" : "Online"}</Col>
          </Row>
          <Row align="middle" justify="space-between">
            <Col>Subtotal:</Col>
            <Col>AED {data?.subtotal}</Col>
          </Row>
          {data?.promodata?.code && (
            <Row align="middle" justify="space-between">
              <Col>Discount:</Col>
              <Col>{data?.promodata?.discount}%</Col>
            </Row>
          )}
          <Row align="middle" justify="space-between">
            <Col>Shipping:</Col>
            <Col>AED {data?.deliverycharge}</Col>
          </Row>
          <Row align="middle" justify="space-between" className="mt-3">
            <Col>
              <strong>Grand Total:</strong>
            </Col>
            <Col>
              <strong>{data?.price}</strong>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="p-3 border rounded my-3" gutter={10}>
        <Col span={24}>
          <h6>Delivered {data?.time}</h6>
          <Table pagination={false} dataSource={tableData} columns={columns} />
        </Col>
      </Row>
    </div>
  );
});

export default PrintInvoice;
