import {
  ClearOutlined,
  DeleteFilled,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Input,
  message,
  Radio,
  Row,
  Space,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Topbar from "common/Topbar";
import { Formik } from "formik";
import { useState } from "react";
import PrivateAxios from "services/axiosService";
import { getUrlExtension, token } from "utils";
import { BASE_URL } from "utils/constants";
import * as Yup from "yup";

import styles from "./AddHomeManagement.module.scss";

const BannerSchema = Yup.object().shape({
  description: Yup.string()
    .min(2, "Too Short!")
    .max(250, "Too Long!")
    .required("Please enter the description name"),
  link: Yup.string()
    .min(5, "Too Short!")
    .max(250, "Too Long!")
    .required("Please enter the link to navigate"),
  isDisplayed: Yup.bool().required(),
});

function AddHomeManagement({ history }) {
  const editData = history?.location?.state || null;
  // console.log(`editData`, editData);
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Banner</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>
        {editData ? "Edit Banners" : "Add Banners"}
      </h3>
    </div>
  );
  const RightComponent = () => <div />;
  const [webImage, setWebImage] = useState(
    editData ? [`${BASE_URL}/${editData?.images?.web}`] : []
  );
  const [mobImage, setMobImage] = useState(
    editData ? [`${BASE_URL}/${editData?.images?.mobile}`] : []
  );

  const handleClear = (clear) => {
    setWebImage([]);
    setMobImage([]);
    clear();
  };

  const [loading, setLoading] = useState(false);
  const imageAppend = async (reqImg, data, name) => {
    if (reqImg?.originFileObj) {
      await data.append(name, reqImg?.originFileObj);
    } else {
      const url = reqImg;
      const imgExt = getUrlExtension(url);
      const fileName = `myFile.${imgExt}`;
      await fetch(url).then(async (response) => {
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        await data.append(name, file);
      });
    }
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    const data = new FormData();
    data.append("description", values.description);
    data.append("link", values.link);
    data.append("status", values.isDisplayed);
    await imageAppend(webImage[0], data, "web");
    await imageAppend(mobImage[0], data, "mobile");

    var config = {
      headers: {
        "x-auth-token": token(),
        "Content-Type": "multipart/form-data",
      },
    };

    PrivateAxios.patch(`/api/banners/${editData?.key}`, data, config)
      .then(function (response) {
        setLoading(false);
        message.success(response?.data?.message);
        history.push("/home-management");
      })
      .catch(function (error) {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <div className={styles.AddHomeManagementWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <Formik
        initialValues={{
          description: editData ? editData?.description : "",
          link: editData ? editData?.link : "",
          isDisplayed: editData ? editData?.status : true,
        }}
        validationSchema={BannerSchema}
        onSubmit={async (values) => {
          if (editData) {
            if (webImage?.length === 0 && mobImage?.length === 0) {
              message?.error("Upload Web and Mobile images");
              return;
            }
            handleUpdate(values);
          } else {
            if (webImage?.length > 0 && mobImage?.length > 0) {
              const data = new FormData();
              data.append("description", values.description);
              data.append("link", values.link);
              data.append("status", values.isDisplayed);
              webImage?.forEach((item) =>
                data.append("web", item?.originFileObj)
              );
              mobImage?.forEach((item) =>
                data.append("mobile", item?.originFileObj)
              );
              const config = {
                headers: {
                  "x-auth-token": token(),
                  "Content-Type": "multipart/form-data",
                },
                data: data,
              };
              PrivateAxios.post("/api/banners", config)
                .then(function (response) {
                  setLoading(false);
                  message.success(response?.data?.message);
                  history.push("/home-management");
                })
                .catch(function (error) {
                  message.error(error?.response?.data?.message);
                  setLoading(false);
                });
            } else {
              message?.error("Upload Web and Mobile images");
            }
          }
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          handleReset,
          setFieldValue,
          values,
        }) => (
          <>
            <div className={styles.content}>
              <p>
                <strong>{editData ? "Edit Banner" : "Add New Banner"}</strong>
              </p>
              <Divider className="my-1" />
              <div className="container px-5 py-3">
                <Row>
                  <Col span={4} />
                  <Col span={12}>
                    <Row
                      gutter={10}
                      align="middle"
                      justify="space-between"
                      className="my-4"
                    >
                      <Col>
                        Images <span className="text-danger">*</span>
                      </Col>
                      <Col>
                        Web Images
                        <br />
                        {webImage?.length > 0 ? (
                          <>
                            <DeleteFilled
                              className={styles.deleteIcon2}
                              onClick={() => setWebImage([])}
                            />
                            <img
                              src={
                                editData
                                  ? webImage[0]?.originFileObj
                                    ? URL?.createObjectURL(
                                        webImage?.[0]?.originFileObj
                                      )
                                    : webImage[0]
                                  : URL?.createObjectURL(
                                      webImage?.[0]?.originFileObj
                                    )
                              }
                              alt="banner"
                              className={styles.webWrap}
                            />
                          </>
                        ) : (
                          <Upload
                            className="w-100"
                            onChange={(e) => setWebImage(e?.fileList)}
                            accept="image/*"
                          >
                            <Button
                              className={styles.webImage}
                              icon={<UploadOutlined />}
                            >
                              Click to Upload
                            </Button>
                          </Upload>
                        )}
                      </Col>
                      <Col span={8}>
                        Mobile Images
                        {mobImage?.length > 0 ? (
                          <>
                            <DeleteFilled
                              className={styles.deleteIcon}
                              onClick={() => setMobImage([])}
                            />
                            <img
                              src={
                                editData
                                  ? mobImage[0]?.originFileObj
                                    ? URL?.createObjectURL(
                                        mobImage?.[0]?.originFileObj
                                      )
                                    : mobImage[0]
                                  : URL?.createObjectURL(
                                      mobImage?.[0]?.originFileObj
                                    )
                              }
                              alt="banner"
                              className={styles.mobWrap}
                            />
                          </>
                        ) : (
                          <Upload
                            className="w-100"
                            onChange={(e) => setMobImage(e?.fileList)}
                            accept="image/*"
                          >
                            <Button
                              className={styles.mobImage}
                              icon={<UploadOutlined />}
                            >
                              Click to Upload
                            </Button>
                          </Upload>
                        )}
                      </Col>
                    </Row>
                    <Row
                      align="middle"
                      justify="space-between"
                      className="my-4"
                    >
                      <Col>
                        Description <span className="text-danger">*</span>
                      </Col>
                      <Col className="w-75">
                        <TextArea
                          rows={5}
                          placeholder="Description"
                          onChange={handleChange("description")}
                          onBlur={handleBlur("description")}
                          value={values.description}
                        />
                        {errors.description && touched.description ? (
                          <pre className="text-danger">
                            {errors.description}
                          </pre>
                        ) : null}
                      </Col>
                    </Row>
                    <Row
                      align="middle"
                      justify="space-between"
                      className="my-4"
                    >
                      <Col>
                        Link <span className="text-danger">*</span>
                      </Col>
                      <Col className="w-75">
                        <Input
                          placeholder="https://"
                          onChange={handleChange("link")}
                          onBlur={handleBlur("link")}
                          value={values.link}
                        />
                        {errors.link && touched.link ? (
                          <pre className="text-danger">{errors.link}</pre>
                        ) : null}
                      </Col>
                    </Row>
                    <Row
                      align="middle"
                      justify="space-between"
                      className="my-4"
                    >
                      <Col>Displayed</Col>
                      <Col className="w-75">
                        <Radio.Group
                          value={values.isDisplayed}
                          buttonStyle="solid"
                          onChange={(e) =>
                            setFieldValue(`isDisplayed`, e?.target?.value)
                          }
                        >
                          <Radio.Button value={true}>Yes</Radio.Button>
                          <Radio.Button value={false}>No</Radio.Button>
                        </Radio.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4} />
                </Row>
              </div>
            </div>
            <div className="container py-2 my-3 bg-white d-flex align-items-center justify-content-center">
              <Space>
                {!editData && (
                  <Button
                    className="d-flex align-items-center"
                    icon={<ClearOutlined />}
                    size="large"
                    onClick={() => handleClear(handleReset)}
                    disabled={loading}
                  >
                    Clear
                  </Button>
                )}
                <Button
                  type="primary"
                  className="d-flex align-items-center"
                  icon={<SaveOutlined />}
                  size="large"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  {editData ? "Update" : "Submit"}
                </Button>
              </Space>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}

export default AddHomeManagement;
