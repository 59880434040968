/* eslint-disable react/prop-types */
import {
  DownOutlined,
  ExclamationCircleOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Layout, Menu, Modal, Row } from "antd";
import CatalogSvg from "assets/icons/catalog.svg";
import CatalogActiveSvg from "assets/icons/catalog-active.svg";
import PaymentSvg from "assets/icons/payment.svg";
import ReportSvg from "assets/icons/report.svg";
import SalesSvg from "assets/icons/sales.svg";
import SalesActiveSvg from "assets/icons/sales-active.svg";
import settings from "assets/icons/settings.svg";
import ShippingSvg from "assets/icons/shipping.svg";
import UsersSvg from "assets/icons/users.svg";
import UsersActiveSvg from "assets/icons/users-active.svg";
import Logo from "assets/images/logo.jpeg";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import styles from "./Layout.module.scss";
const { Header, Sider, Content } = Layout;
function Wrapper({ children }) {
  window.addEventListener("storage", () => {
    history.push("/login");
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { SubMenu } = Menu;
  const history = useHistory();
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => history.push("/")}>
        Dashboard
      </Menu.Item>
      <Menu.Item key="2" onClick={() => history.push("/orders")}>
        Orders
      </Menu.Item>
      <Menu.Item key="3" onClick={() => history.push("/products")}>
        Products
      </Menu.Item>
      <Menu.Item key="4" onClick={() => history.push("/users")}>
        User Management
      </Menu.Item>
      <Menu.Item key="5" onClick={() => history.push("/home-management")}>
        Home Management
      </Menu.Item>
    </Menu>
  );

  const handleLogout = () => {
    Modal.confirm({
      title: "Logout",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want logout?",
      okText: "Sure",
      cancelText: "Cancel",
      onOk: () => {
        localStorage.clear();
        history.push("/login");
      },
    });
  };
  const { pathname } = window.location;

  const isSales =
    pathname === "/sales" ||
    pathname === "/new-sale" ||
    pathname === "/invoice" ||
    pathname === "/orders";

  const isCatalog =
    pathname === "/products" ||
    pathname === "/category" ||
    pathname === "/add-products" ||
    pathname === "/add-category";

  const isUser = pathname === "/users";

  return (
    <Layout className={styles.LayoutWrapper}>
      <Sider className={styles.sidebar} width="232">
        <div className={styles.logoWrapper}>
          <img src={Logo} alt="logo" className={styles.loginLogo} />
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={["sub1"]}
          defaultOpenKeys={["sub1"]}
          style={{ height: "100%", borderRight: 0 }}
        >
          <SubMenu
            key="sub2"
            icon={
              isSales ? <img src={SalesActiveSvg} /> : <img src={SalesSvg} />
            }
            title="Orders"
            className={styles.subMenu}
          >
            <Menu.Item key="5" onClick={() => history.push("/orders")}>
              All Orders
            </Menu.Item>
            <Menu.Item key="51" onClick={() => history.push("/new-orders")}>
              New Orders
            </Menu.Item>
            <Menu.Item
              key="53"
              onClick={() => history.push("/processing-orders")}
            >
              Processing Orders
            </Menu.Item>
            <Menu.Item
              key="54"
              onClick={() => history.push("/delivered-orders")}
            >
              Delivered Orders
            </Menu.Item>
            <Menu.Item
              key="56"
              onClick={() => history.push("/cancelled-orders")}
            >
              Cancelled Orders
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub4"
            icon={
              isCatalog ? (
                <img src={CatalogActiveSvg} />
              ) : (
                <img src={CatalogSvg} />
              )
            }
            title="Catalog"
            className={styles.subMenu}
          >
            <Menu.Item key="9" onClick={() => history.push("/products")}>
              Product
            </Menu.Item>
            <Menu.Item key="10" onClick={() => history.push("/category")}>
              Categories
            </Menu.Item>
            <Menu.Item key="10.1" onClick={() => history.push("/offers")}>
              Offers
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub5"
            icon={
              isUser ? <img src={UsersActiveSvg} /> : <img src={UsersSvg} />
            }
            title="User Management"
            className={styles.subMenu}
          >
            <Menu.Item key="11" onClick={() => history.push("/users")}>
              Manage Buyers
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="Page"
            icon={isUser ? <img src={ReportSvg} /> : <img src={ReportSvg} />}
            title="Page Management"
            className={styles.subMenu}
          >
            <Menu.Item
              key="Page1"
              onClick={() => history.push("/home-management")}
            >
              Home
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="sub6"
            icon={
              isSales ? <img src={ShippingSvg} /> : <img src={ShippingSvg} />
            }
            title="Shipping"
            className={styles.subMenu}
          >
            <Menu.Item key="61" onClick={() => history.push("/emirates")}>
              Emirates
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="sub7"
            icon={<img src={PaymentSvg} />}
            title="Payment"
            className={styles.subMenu}
          >
            Payment
          </Menu.Item>
          <Menu.Item
            key="sub711"
            icon={<img src={settings} className={styles.settings} />}
            title="Settings"
            className={styles.subMenu}
            onClick={() => history.push("/settings")}
          >
            Settings
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className={styles.header}>
          <Row>
            <Col span={8} />
            <Col span={8}>
              <div className="d-flex align-items-center justify-content-center w-100">
                <Dropdown overlay={menu}>
                  <Button>
                    Quick Access <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </Col>
            <Col span={8}>
              <div className="d-flex align-items-center justify-content-end ">
                <Button
                  shape="circle"
                  icon={<PoweroffOutlined />}
                  size="middle"
                  onClick={handleLogout}
                />
              </div>
            </Col>
          </Row>
        </Header>
        <Content className={styles.content}>
          <div>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Wrapper;
