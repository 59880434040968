import { Button, Col, Input, message, Modal, Row, Space } from "antd";
import axios from "axios";
import { Formik } from "formik";
import { useState } from "react";
import { BASE_URL } from "utils/constants";
import * as Yup from "yup";

import styles from "./AddEmirate.module.scss";

function EditEmirate({ addEmirate, setEmirate, fetch, data }) {
  const ProductionSchema = Yup.object().shape({
    name: Yup.string().required("Enter emirate name"),
  });
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.AddEmirateWrapper}>
      <Modal
        title="Add Emirate"
        destroyOnClose
        visible={addEmirate}
        onOk={() => setEmirate(null)}
        onCancel={() => setEmirate(null)}
        footer={null}
      >
        <Formik
          initialValues={{
            name: data?.name,
          }}
          validationSchema={ProductionSchema}
          onSubmit={(values) => {
            setLoading(true);
            const data1 = JSON.stringify({
              name: values?.name,
            });

            const config = {
              method: "patch",
              url: `${BASE_URL}/api/emirates/${data?._id}`,
              headers: {
                "Content-Type": "application/json",
              },
              data: data1,
            };

            axios(config)
              .then(function (response) {
                setLoading(false);
                message.success(response?.data?.message);
                setEmirate(null);
                fetch();
              })
              .catch(function (error) {
                setLoading(false);

                message.error(
                  error?.response?.data?.message || "Something went wrong!"
                );
              });
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            values,
          }) => (
            <>
              <div className="pb-3">
                <p className="mb-1">
                  Emirate Name <span className="text-danger">*</span>
                </p>
                <Input
                  placeholder="Enter Emirate Name"
                  onChange={handleChange("name")}
                  onBlur={handleBlur("name")}
                  value={values.name}
                />
                {errors.name && touched.name ? (
                  <pre className="text-danger">{errors?.name}</pre>
                ) : null}
              </div>
              <Row justify="end">
                <Space>
                  <Col>
                    <Button
                      disabled={loading}
                      onClick={() => setEmirate(false)}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={handleSubmit}
                      loading={loading}
                      type="primary"
                    >
                      Submit
                    </Button>
                  </Col>
                </Space>
              </Row>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default EditEmirate;
