import "react-tagsinput/react-tagsinput.css";

import TagsInput from "react-tagsinput";

import styles from "./TagsInput.module.scss";

function TagsInputComponet({ tags, setTags }) {
  const handleChange = (tags) => setTags(tags);
  return (
    <div className={styles.TagsInputWrapper}>
      <TagsInput
        value={tags}
        onChange={(tag) => handleChange(tag)}
        placeholder="Add keyword"
      />
    </div>
  );
}

export default TagsInputComponet;
