/* eslint-disable react/prop-types */
import Wrapper from "common/Wrapper";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isLogin } from "utils";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <Wrapper>
            <Component {...props} />
          </Wrapper>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
