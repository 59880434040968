import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  message,
  Row,
  Select,
  TreeSelect,
  Upload,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { TreeNode } from "antd/lib/tree-select";
import TagsInputComponet from "common/TagsInputComponet";
import Topbar from "common/Topbar";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PrivateAxios from "services/axiosService";
import { getUrlExtension, token } from "utils";
import { BASE_URL, Parent_Category } from "utils/constants";
import * as Yup from "yup";
const { TextArea } = Input;
const { Option } = Select;

import styles from "./AddProduct.module.scss";

function EditProduct() {
  const [imageUrl, setImageUrl] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [result, setresult] = useState([]);
  const [selCategory, setSelCategory] = useState([]);
  const [emirates, setEmirates] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const data = history?.location?.state;
  const [tags, setTags] = useState(data?.seo?.metatags);

  const onImageChange = (e, type) => {
    type === "main" ? setImageUrl(e?.fileList) : setGallery(e?.fileList);
  };

  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/products">Catalog</Breadcrumb.Item>
        <Breadcrumb.Item href="/products">Products</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}> Edit Products</h3>
    </div>
  );

  const RightComponent = () => <div />;

  const ProductSchema = Yup.object().shape({
    pcode: Yup.string().required("Please enter the product code"),
    name: Yup.string()
      .min(2, "Too Short!")
      .max(150, "Too Long!")
      .required("Please enter the product name"),
    smalldescription: Yup.string(),
    description: Yup.string(),
    qty: Yup.number("Enter valid quantity of product").required(
      "Enter quantity of product"
    ),
    price: Yup.number("Enter valid price of product").required(
      "Enter price of product"
    ),
    unit: Yup.string().required("Please choose a unit"),
    metaNames: Yup.string(),
    isFeatured: Yup.bool(),
    isVisible: Yup.bool(),
  });

  const fetchCategory = () => {
    let config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get("/api/categories", config)
      .then(function (response) {
        setresult(response?.data?.data);
      })
      .catch(function (error) {
        message.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (result?.length === 0) fetchCategory();
  }, []);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onSubmit = (submit) => {
    if (imageUrl?.length > 0) {
      submit();
    } else {
      message.error("Please attach images to continue");
    }
  };

  const showLeafIcon = false;
  const RootArr = (data) => {
    const Tree = data?.map((item) => (
      <TreeNode key={item?._id} value={item?._id} title={item?.name}>
        {RootArr(item?.subcategories)}
      </TreeNode>
    ));
    return Tree;
  };

  const getEmirates = () => {
    PrivateAxios.get("/api/emirates")
      .then(function (response) {
        const data = response?.data?.data?.map((item, i) => ({
          key: i + 1,
          name: item?.name,
          _id: item?._id,
        }));
        setEmirates(data);
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };

  useEffect(() => {
    if (emirates?.length === 0) getEmirates();
  }, []);

  const handleCategorySel = (id) => {
    setSelCategory(id);
  };

  useEffect(() => {
    const category = data?.category?.map((item) => item?._id);
    setSelCategory(category);
    if (data?.image && imageUrl?.length === 0) {
      setImageUrl([{ thumbUrl: data?.image }]);
    }
    if (data?.imageArr?.length > 0 && gallery?.length === 0) {
      const galleryArr = data?.imageArr?.map((item) => ({
        thumbUrl: BASE_URL + "/" + item,
      }));
      setGallery(galleryArr);
    }
  }, [data]);

  const imageAppend = async (reqImg, data, name) => {
    if (reqImg?.originFileObj) {
      await data.append(name, reqImg?.originFileObj);
    } else {
      const url = reqImg?.thumbUrl;
      const imgExt = getUrlExtension(url);
      const fileName = `myFile.${imgExt}`;
      await fetch(url).then(async (response) => {
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        await data.append(name, file);
      });
    }
  };

  const id = data?._id;

  const handleImageUpload = async (formData) => {
    const reqImg = imageUrl?.[0];
    await imageAppend(reqImg, formData, "mainimage");
    gallery?.forEach(
      async (item) => await imageAppend(item, formData, "gallery")
    );
    return true;
  };

  return (
    <div className={styles.AddProductWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className="d-flex justify-content-between">
        <div className={styles.content}>
          <Formik
            initialValues={{
              pcode: data?.code ? data.code : "",
              name: data?.name,
              arName: data?.arName,
              smalldescription: data?.smalldescription ?? "",
              description: data?.description ?? "",
              arDescription: data?.arDescription ?? "",
              qty: data?.quantity,
              price: data?.price,
              unit: data?.unit,
              metaNames: data?.seo?.metaname,
              isFeatured: data?.isfeatured,
              isVisible: data?.status,
            }}
            validationSchema={ProductSchema}
            onSubmit={async (values) => {
              if (selCategory?.length === 0) {
                message.error("Please select category to continue");
                return;
              }

              console.log(values);

              setLoading(true);
              let data = new FormData();
              await handleImageUpload(data);
              data.append("code", values.pcode);
              data.append("name", values.name);
              data.append("arName", values.arName);
              data.append("smalldescription", values.smalldescription);
              data.append("quantity", values.qty);
              data.append("price", values.price);
              data.append("unit", values.unit);
              data.append("description", values.description);
              data.append("arDescription", values.arDescription);
              data.append("categories", JSON.stringify(selCategory));
              data.append("isfeatured", values.isFeatured);
              data.append("isvisible", values.isVisible);
              data.append("metaname", values.metaNames);
              data.append("metatags", JSON.stringify(tags));
              imageUrl?.forEach((item) =>
                data.append("mainimage", item?.originFileObj)
              );

              const config = {
                headers: {
                  "x-auth-token": token(),
                  "Content-Type": "multipart/form-data",
                },
              };

              PrivateAxios.patch(`/api/products/${id}`, data, config)
                .then(function (response) {
                  setLoading(false);
                  message.success(response?.data?.message);
                  history.push("/products");
                })
                .catch(function (error) {
                  message.error(error?.response?.data?.message);
                  setLoading(false);
                });
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              //   handleReset,
              setFieldValue,
              values,
            }) => (
              <>
                <div className="mb-3">
                  <p>
                    Product Code<span className="text-danger">*</span>
                  </p>
                  <Input
                    placeholder="Product code"
                    onChange={handleChange("pcode")}
                    onBlur={handleBlur("pcode")}
                    value={values.pcode}
                  />
                  {errors.pcode && touched.pcode ? (
                    <pre className="text-danger">{errors.pcode}</pre>
                  ) : null}
                </div>
                <div className="mb-3">
                  <p>
                    Product Name In English
                    <span className="text-danger">*</span>
                  </p>
                  <Input
                    placeholder="Product Name In English"
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values.name}
                  />{" "}
                  {errors.name && touched.name ? (
                    <pre className="text-danger">{errors.name}</pre>
                  ) : null}
                </div>
                <div className="mb-3">
                  <p>
                    Product Name In Arabic<span className="text-danger">*</span>
                  </p>
                  <Input
                    placeholder="Product Name In Arabic"
                    onChange={handleChange("arName")}
                    onBlur={handleBlur("arName")}
                    value={values.arName}
                  />{" "}
                  {errors.arName && touched.arName ? (
                    <pre className="text-danger">{errors.arName}</pre>
                  ) : null}
                </div>
                <div className="mb-3">
                  <p>
                    Short Description<span className="text-danger">*</span>
                  </p>
                  <TextArea
                    rows={3}
                    placeholder="Short Description"
                    onChange={handleChange("smalldescription")}
                    onBlur={handleBlur("smalldescription")}
                    value={values.smalldescription}
                  />
                  {errors.smalldescription && touched.smalldescription ? (
                    <pre className="text-danger">{errors.smalldescription}</pre>
                  ) : null}
                </div>
                <Row gutter={15}>
                  <Col span={8} className="mb-3">
                    <h6>
                      <strong>Stock</strong>
                    </h6>
                    <p>
                      Quantity<span className="text-danger">*</span>
                    </p>
                    <Input
                      placeholder="Qty"
                      onChange={handleChange("qty")}
                      onBlur={handleBlur("qty")}
                      value={values.qty}
                      type="number"
                    />{" "}
                    {errors.qty && touched.qty ? (
                      <pre className="text-danger">{errors.qty}</pre>
                    ) : null}
                  </Col>
                  <Col span={16}>
                    <Row gutter={10}>
                      <Col span={12} className="mb-3">
                        <h6>
                          <strong>Price Details</strong>
                        </h6>
                        <p>
                          Product Price<span className="text-danger">*</span>
                        </p>
                        <Input
                          addonBefore="AED"
                          placeholder="Price"
                          onChange={handleChange("price")}
                          onBlur={handleBlur("price")}
                          value={values.price}
                          type="number"
                        />

                        {errors.price && touched.price ? (
                          <pre className="text-danger">{errors.price}</pre>
                        ) : null}
                      </Col>
                      <Col span={12} className="mb-3">
                        <h6>&nbsp;</h6>
                        <p>
                          Unit<span className="text-danger">*</span>
                        </p>
                        <Select
                          defaultValue="kg"
                          className="w-100"
                          value={values.unit}
                          onChange={handleChange("unit")}
                          onBlur={handleBlur("unit")}
                        >
                          <Option value="pcs">Pieces</Option>
                          <Option value="pkts">Packets</Option>
                          <Option value="trays">Trays</Option>
                        </Select>
                        {errors.unit && touched.unit ? (
                          <pre className="text-danger">{errors.unit}</pre>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="mb-3">
                  <p>
                    Description In English<span className="text-danger">*</span>
                  </p>
                  <TextArea
                    rows={6}
                    placeholder="Description In English"
                    onChange={handleChange("description")}
                    onBlur={handleBlur("description")}
                    value={values.description}
                  />
                  {errors.description && touched.description ? (
                    <pre className="text-danger">{errors.description}</pre>
                  ) : null}
                </div>
                <div className="mb-3">
                  <p>
                    Description In Arabic<span className="text-danger">*</span>
                  </p>
                  <TextArea
                    rows={6}
                    placeholder="Description In Arabic"
                    onChange={handleChange("arDescription")}
                    onBlur={handleBlur("arDescription")}
                    value={values.arDescription}
                  />
                  {errors.arDescription && touched.arDescription ? (
                    <pre className="text-danger">{errors.arDescription}</pre>
                  ) : null}
                </div>
                <div className="mb-3">
                  <p>
                    Category<span className="text-danger">*</span>
                  </p>
                  <TreeSelect
                    treeLine={true && { showLeafIcon }}
                    style={{ width: "100%" }}
                    placeholder="Please select a category"
                    showSearch
                    multiple
                    onChange={(e) => handleCategorySel(e)}
                    value={selCategory}
                  >
                    <TreeNode value={Parent_Category} title="Root" disabled>
                      {RootArr(result)}
                    </TreeNode>
                  </TreeSelect>

                  {errors.category && touched.category ? (
                    <pre className="text-danger">{errors.category}</pre>
                  ) : null}
                </div>

                <Row className="mb-3">
                  <Col span={8}>
                    <Checkbox
                      checked={values.isFeatured}
                      onChange={(e) => {
                        setFieldValue("isFeatured", e?.target?.checked);
                      }}
                    >
                      Is Featured?
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      checked={values.isVisible}
                      onChange={(e) =>
                        setFieldValue("isVisible", e?.target?.checked)
                      }
                    >
                      Is Visible?
                    </Checkbox>
                  </Col>
                </Row>

                <div className="pt-3">
                  <h6>
                    <strong>For SEO</strong>
                  </h6>
                  <p>Meta Name / Slug</p>
                  <Input
                    placeholder="Enter Meta Name"
                    onChange={handleChange("metaNames")}
                    onBlur={handleBlur("metaNames")}
                    value={values.metaNames}
                  />

                  {errors.metaNames && touched.metaNames ? (
                    <pre className="text-danger">{errors.metaNames}</pre>
                  ) : null}

                  <p className="pt-3">Meta Tag</p>
                  <TagsInputComponet tags={tags} setTags={setTags} />
                </div>
                <span className="d-flex align-items-center">
                  <h5 className="text-bold mt-3">Images</h5>
                  {/* <p className="mb-0 mt-2 ml-2">&nbsp;(Max 4 images)</p> */}
                </span>
                <Row>
                  <Col>
                    <p>
                      Main Image <span className="text-danger">*</span>
                    </p>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={true}
                      onChange={(e) => onImageChange(e, "main")}
                      accept="image/*"
                      fileList={imageUrl}
                      onSubmit={(e) => {
                        console.log("img", e);
                      }}
                      maxCount={1}
                    >
                      {uploadButton}
                    </Upload>
                  </Col>
                  <Col>
                    <div className="pl-3">
                      <p>Gallery Image (Max 3)</p>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={true}
                        onChange={(e) => onImageChange(e, "gallery")}
                        accept="image/*"
                        fileList={gallery}
                        onSubmit={(e) => {
                          console.log("img", e);
                        }}
                        maxCount={3}
                      >
                        {uploadButton}
                      </Upload>
                    </div>
                  </Col>
                </Row>
                {/* <pre>* First Image will be main (thumbnail) product image </pre> */}
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <Button
                    onClick={() => onSubmit(handleSubmit)}
                    type="primary"
                    icon={<SaveOutlined />}
                    size="large"
                    loading={loading}
                    className="d-flex align-items-center"
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
