/* eslint-disable react/prop-types */

import { ClearOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import Meta from "antd/lib/card/Meta";
import Modal from "antd/lib/modal/Modal";
import Topbar from "common/Topbar";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import PrivateAxios from "services/axiosService";
import { token } from "utils";
import * as Yup from "yup";

import styles from "./UserDetails.module.scss";

const CreditSchema = Yup.object().shape({
  id: Yup.string().required("Please choose a order"),
  amount: Yup.string().required("Please enter the due amount"),
});

function UserDetails({ history }) {
  const userId = history?.location?.pathname?.replace("/users/", "");
  const user = history?.location?.state?.data;
  const [result, setResults] = useState([]);
  const getAllSalesOfUser = () => {
    const config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get(`/api/user-sales/${userId}`, config)
      .then(function (response) {
        setResults(response?.data);
        setLoading(false);
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    // getSalesOverview();
    getAllSalesOfUser();
  }, []);

  const [loading, setLoading] = useState(true);
  const [showModal, setModal] = useState(false);

  const columns = [
    {
      title: "Invoice ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Total",
      key: "total",
      render: (data) => <span>{`AED ${data?.total?.toFixed(2)}`}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "payment",
      key: "payment",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Receipt",
      key: "receipt",
      render: (data) => (
        <Button type="primary" onClick={() => getOrderById(data?.id)}>
          Receipt
        </Button>
      ),
    },
  ];

  const dataSource = result?.data?.map((item) => {
    let product = "";
    item?.orderitems?.forEach((element) => {
      product = `${product}${element?.name},`;
    });
    return {
      id: item?.invoicenumber,
      time: moment(new Date(item?.date)).format("DD/MM/YYYY"),
      items: product,
      total: item?.nettotal,
      payment: item?.paymenttype,
      status: item?.status,
      discount: item?.salesdiscount,
      // ...item,
    };
  });

  const getOrderById = (id) => {
    const config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get(`/api/sales/${id}`, config)
      .then(function (response) {
        history.push("/invoice", { data: response.data.data });
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Manage Customers</Breadcrumb.Item>
        <Breadcrumb.Item>Customer</Breadcrumb.Item>
      </Breadcrumb>
      <h6 className={styles.heading}>{user?.username}</h6>
      <h6 className={styles.subheading}>{user.phonenumber}</h6>
    </div>
  );

  const RightComponent = () => (
    <Button
      type="primary"
      icon={<ClearOutlined />}
      size="large"
      className={styles.btn}
      onClick={() => setModal(true)}
    >
      Clear Due
    </Button>
  );
  const { Option } = Select;
  return (
    <div className={styles.UserDetailsWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className="my-2">
        <Row gutter={16}>
          <Col span={6} className="my-1">
            <Card title="Card Sales">{result?.cardsales}</Card>
          </Col>
          <Col span={6} className="my-1">
            <Card title="Credit Sales">{result?.creditsales}</Card>
          </Col>
          <Col span={6} className="my-1">
            <Card title="Cash Sales">{result?.cashsales}</Card>
          </Col>
          <Col span={6} className="my-1">
            <Card title="Due Amount">
              <span className="text-danger text-bold">
                AED {result?.dueamount}
              </span>
            </Card>
          </Col>
          <Col span={6} className="my-1">
            <Card title="Due Sales">
              <span className="text-danger text-bold">{result?.duesale}</span>
            </Card>
          </Col>
          <Col span={6} className="my-1">
            <Card title="Over Due Amount">
              <strong className="text-danger text-bold">
                AED {result?.overdueamount}
              </strong>
            </Card>
          </Col>
          <Col span={6} className="my-1">
            <Card title="Over Dues Sales">
              <strong className="text-danger mb-0 text-bold">
                {result?.overduesales}
              </strong>
            </Card>
          </Col>
          <Col span={6} className="my-1">
            <Card title="Total Sales">{result?.totalsales}</Card>
          </Col>
        </Row>
      </div>
      <Card className="mb-3" loading={loading}>
        <Meta
          avatar={<Avatar>{user?.username?.[0]}</Avatar>}
          title="Address"
          description={user?.address || "No Address"}
        />
      </Card>

      <Table dataSource={dataSource} columns={columns} loading={loading} />
      <Modal
        title="Clear Due"
        visible={showModal}
        onCancel={() => setModal(false)}
        footer={null}
        destroyOnClose
      >
        <Formik
          initialValues={{
            id: "",
            amount: "",
          }}
          validationSchema={CreditSchema}
          onSubmit={(values, action) => {
            const data1 = JSON.stringify({
              amount: values?.amount,
            });

            const config = {
              headers: {
                "x-auth-token": token(),
                "Content-Type": "application/json",
              },
            };

            PrivateAxios.patch(`/api/sales/${values?.id}`, data1, config)
              .then(function (response) {
                message.success(response?.data?.message);
                getAllSalesOfUser();
                action.resetForm();
                setModal(false);
              })
              .catch(function (error) {
                message.error(error?.response?.data?.message);
              });
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            values,
          }) => (
            <div>
              <p className="mb-2"> Credit Against</p>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select the Order"
                optionFilterProp="children"
                onSelect={(e) => setFieldValue("id", e)}
                onBlur={handleBlur("id")}
                value={values.id}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {dataSource
                  ?.filter(
                    (item) =>
                      item?.payment === "credit" && item?.status === "pending"
                  )
                  ?.map((item) => (
                    <Option
                      key={item?._id}
                      value={item?.id}
                    >{`ID: ${item?.id} | Price: AED ${item?.total} - Date: (${item?.time})`}</Option>
                  ))}
              </Select>
              {errors.id && touched.id ? (
                <pre className="text-danger">{errors.id}</pre>
              ) : null}
              <p className="mb-2 mt-2">Credit Amount</p>
              <Input
                addonBefore="AED"
                onChange={handleChange("amount")}
                onBlur={handleBlur("amount")}
                value={values.amount}
              />
              {errors.amount && touched.amount ? (
                <pre className="text-danger">{errors.amount}</pre>
              ) : null}
              <div className="mt-3 w-100 d-flex align-items-center justify-content-end">
                <Space>
                  <Button onClick={() => setModal(false)}>Cancel</Button>
                  <Button onClick={handleSubmit} type="primary">
                    OK
                  </Button>
                </Space>
              </div>
            </div>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default UserDetails;
