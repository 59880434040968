import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  EditFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Divider,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Space,
  Table,
} from "antd";
import EditCategory from "common/EditCategory";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import PrivateAxios from "services/axiosService";
import { token } from "utils";

import styles from "./Subcategory.module.scss";

function Subcategory({ history }) {
  const [isModalVisible, setIsModalVisible] = useState(null);
  const [loading, setLoading] = useState(false);

  const showModal = (data) => {
    setIsModalVisible(data);
  };

  const { categoryArr, record } = history?.location?.state;

  useEffect(() => {
    if (!categoryArr) fetchCategory();
  }, []);

  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Catalog</Breadcrumb.Item>
        <Breadcrumb.Item href="/category">Categories</Breadcrumb.Item>
        <Breadcrumb.Item>Sub Categories</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>{record?.name}</h3>
    </div>
  );
  const [result, setresult] = useState(categoryArr);

  const fetchCategory = () => {
    let config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get("/api/categories", config)
      .then(function (response) {
        setLoading(false);
        setresult(response?.data?.data);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };
  const RightComponent = () => (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      size="large"
      className={styles.btn}
      onClick={() => history.push("/add-category", { data: categoryArr })}
    >
      Add Category
    </Button>
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (src) => (
        <img src={src} alt="thumbnail" className={styles.productImg} />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Displayed",
      key: "active",
      render: (bool) => (
        <Space size="middle">
          {bool?.active ? (
            <CheckOutlined className="text-success" />
          ) : (
            <CloseOutlined className="text-danger" />
          )}
          <p>{bool?.active}</p>
        </Space>
      ),
    },
    {
      title: "Position",
      key: "index",
      render: ({ index }) => (
        <Space size="middle" onClick={(e) => e?.stopPropagation()}>
          <InputNumber
            value={index}
            onClick={(e) => e?.stopPropagation()}
            onChange={(e) => {
              console.log(`e`, e);
            }}
          />
        </Space>
      ),
    },
    {
      title: "Edit",
      key: "edit",
      render: (data) => (
        <Space size="middle">
          <EditFilled
            onClick={(e) => {
              e?.stopPropagation();
              showModal(data);
            }}
          />
        </Space>
      ),
    },

    {
      title: "Delete",
      key: "delete",
      render: (item) => (
        <Space size="middle" onClick={(e) => e?.stopPropagation()}>
          <Popconfirm
            onClick={(e) => e?.stopPropagation()}
            title="Are you sure to delete this Category?"
            onConfirm={() => handleDelete(item?.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <DeleteFilled
              className="text-danger"
              onClick={(e) => e?.stopPropagation()}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleDelete = (id) => {
    setLoading(true);
    let config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.delete(`/api/categories/${id}`, config)
      .then(function (response) {
        setLoading(false);
        message.success(response?.data?.message);
        fetchCategory();
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const getChildren = (children) => {
    const out = children?.map((child, i) => ({
      index: i + 1,
      key: child?.key,
      id: child?.id,
      name: child?.name,
      description: child?.description,
      active: child?.active,
      image: child?.image,
      children: child?.children?.length > 0 ? getChildren(child?.children) : [],
      ...children,
    }));
    return out;
  };

  const data = record?.children?.map((item, i) => ({
    index: i + 1,
    key: item?.key,
    id: item?.id,
    name: item?.name,
    description: item?.description,
    active: item?.active,
    image: item?.image,
    children: item?.children?.length > 0 ? getChildren(item?.children) : [],
    ...item,
  }));

  return (
    <div className={styles.SubcategoryWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className={styles.content}>
        <div>
          <h6 className="mb-2 pt-4 text-success">
            Categories({record?.children?.length || 0})
          </h6>
        </div>
        <Divider className="my-2" />
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          onRow={(record) => {
            return {
              onClick: () =>
                history.push("/subcategory", {
                  record: record,
                  categoryArr: categoryArr,
                }), // click row
            };
          }}
        />
      </div>
      <Modal
        footer={null}
        title="Edit Category"
        visible={isModalVisible !== null}
        setIsModalVisible={setIsModalVisible}
        onCancel={() => setIsModalVisible(null)}
        destroyOnClose={true}
        width={"80%"}
      >
        <EditCategory
          data={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          fetchCategory={fetchCategory}
          categoryArr={result}
        />
      </Modal>
    </div>
  );
}

export default Subcategory;
