/* eslint-disable react/prop-types */
import { EditFilled } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Option } from "antd/lib/mentions";
import Text from "antd/lib/typography/Text";
import Topbar from "common/Topbar";
import moment from "moment";
import { useState } from "react";
import PrivateAxios from "services/axiosService";
import { token } from "utils";

import styles from "./Invoice.module.scss";

function Invoice({ history }) {
  const { data } = history?.location?.state || {};
  const status = data?.order?.status || data?.status;
  const RightComponent = () => <div />;
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/sales">Sales</Breadcrumb.Item>
        <Breadcrumb.Item href="/new-sale">New Sales</Breadcrumb.Item>
        <Breadcrumb.Item>
          {status === "pending" ? "Orders" : "Invoice"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>
        {status === "pending" ? "Orders" : "Invoice"}
      </h3>
    </div>
  );

  // const { mobilereciept, notes, products, user, salesdiscount } = data || {};

  const orders = data?.orderitems || data?.order?.orderitems;
  const dataSource = orders?.map((item, key) => ({
    key: key + 1,
    item: item?.name,
    price: item?.price,
    qty: item?.qty,
    total: item?.total,
    ...item,
  }));

  const columns = [
    {
      title: "SI No.",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Item Name(s)",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Price",
      key: "price",
      render: (text) => <span>{text?.price?.toFixed(2)}</span>,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Total",
      key: "total",
      render: (text) => <span>{text?.total?.toFixed(2)}</span>,
    },
  ];

  const Footer = () => (
    <div className={styles.footer}>
      <hr />
      <Row>
        <Col span={12}></Col>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <p>Sub Total</p>
              <p>Discount</p>
              <p>Delivery Fee</p>
              <p>Vat </p>
              <p>Adjuments </p>
              <p>Number of items</p>
            </Col>
            <Col span={12}>
              <p>
                AED{" "}
                {data?.subtotal?.toFixed(2) ||
                  data?.order?.subtotal?.toFixed(2)}{" "}
              </p>
              <p>
                AED{" "}
                {status !== "completed"
                  ? data?.salesdiscount
                  : data?.order?.salesdiscount || data?.salesdiscount || 0}
              </p>
              <p>
                AED{" "}
                {status !== "completed"
                  ? data?.deliverycharge
                  : data?.order?.deliverycharge || data?.deliverycharge || 0}
              </p>

              <p>
                AED{" "}
                {data?.totalvat?.toFixed(2) ||
                  data?.order?.totalvat?.toFixed(2)}{" "}
              </p>
              <p>
                AED{" "}
                {data?.adjustment?.toFixed(2) ||
                  data?.order?.adjustment?.toFixed(2)}{" "}
              </p>
              <p>{orders?.length} </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={12}></Col>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <p>
                <Text strong className="text-right" align="right">
                  Total
                </Text>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <Text strong className={styles.bold}>
                  AED {data?.nettotal?.toFixed(2)}
                </Text>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
  const { TextArea } = Input;

  const [payment, setPayment] = useState("cash");
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState(false);
  const [isChecked, setShow] = useState(false);
  const [showCredit, setShowCredit] = useState(false);
  const [credit, setCredit] = useState(0);

  const handlePrint = (id) => {
    const url = `http://localhost:1323/print?id=${id}`;
    const win = window.open(url, "_blank");

    setTimeout(() => win.close(), 1000);
  };

  const handleSubmit = (id) => {
    setLoading(true);
    const data = JSON.stringify({
      method: payment,
      days: credit,
      comments: comments,
      showcomment: isChecked,
    });

    const config = {
      headers: {
        "x-auth-token": token(),
        "Content-Type": "application/json",
      },
    };

    PrivateAxios.post(`/api/complete-sales/${data?._id || id}`, data, config)
      .then(function (response) {
        setLoading(false);
        message.success(response?.data?.message);
        handlePrint(response?.data?.salesId);

        history?.push("/");
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message || "Something went wrong");
      });
  };

  return (
    <div className={styles.InvoiceWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className="pt-5 w-100">
        <Row gutter={25}>
          <Col span={16} className="mr-3">
            {status === "pending" && (
              <div className="bg-white w-100 px-3 py-2">
                <Button
                  icon={<EditFilled />}
                  className="d-flex align-items-center "
                  type="primary"
                  onClick={() => history.push("new-sale", { data })}
                >
                  Edit
                </Button>
              </div>
            )}

            <div className="bg-white w-100 mt-3 p-3">
              <Row>
                <Col span={8}>
                  <Text strong>Golden Ribbon Chocolates</Text>
                  <br />
                  <p>Al Muntazah Plaza, Al Qulayya,Sharjah</p>
                </Col>
                <Col span={8}>
                  <p>
                    Bill Date:{" "}
                    {moment(new Date(data?.date)).format("DD/MM/YY hh:mm A")}{" "}
                  </p>
                  <p className="text-ellipsis">
                    {/* Order No: <span>{data?.ordernumber}</span> */}
                    {status === "completed" ? (
                      <span>
                        {data?.invoicenumber ? (
                          <span>
                            Invoice No: <span>{data?.invoicenumber}</span>{" "}
                          </span>
                        ) : (
                          <span>
                            Order No: <span>{data?.ordernumber}</span>{" "}
                          </span>
                        )}
                      </span>
                    ) : (
                      <span>
                        Order No: <span>{data?.ordernumber}</span>{" "}
                      </span>
                    )}
                  </p>
                  {status === "completed" && (
                    <p>Order No: {data?.order?.ordernumber}</p>
                  )}
                  {(data?.placedby || data?.order?.placedby) && (
                    <p>Employee: {data?.placedby || data?.order?.placedby}</p>
                  )}
                </Col>
                <Col span={8}>
                  <p>Bill To </p>
                  <p>Name: {data?.user?.username}</p>
                  <p>Phone: {data?.user?.phonenumber}</p>
                </Col>
              </Row>
              <Divider className="mt-3 mb-1" />
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
              <Footer />
            </div>
          </Col>
          {status === "pending" ? (
            <Col span={8}>
              <div className="bg-white p-3">
                <Row align="middle" justify="space-between">
                  <Col>
                    <h6>Delivery Fee</h6>
                  </Col>
                  <Col>
                    <h6 className="d-flex align-items-center text-danger">
                      AED&nbsp;
                      <h6 className="text-danger mb-0">
                        {data?.deliverycharge?.toFixed(2) || 0.0}
                      </h6>
                    </h6>
                  </Col>
                </Row>
                <Row align="middle" justify="space-between">
                  <Col>
                    <h6>Total Amount</h6>
                  </Col>
                  <Col>
                    <h6 className="d-flex align-items-center text-success">
                      AED&nbsp;
                      <h4 className="text-success">
                        {data?.nettotal?.toFixed(2)}
                      </h4>
                    </h6>
                  </Col>
                </Row>
                <Row
                  className="w-100 my-3"
                  align="middle"
                  justify="space-between"
                >
                  <Col>
                    <p>Status</p>
                  </Col>
                  <Col>
                    <Tag color="orange">{data?.status}</Tag>
                  </Col>
                </Row>
                <Divider className="my-2" />
                <p>Payment Type</p>
                <Row className="w-100 my-3" align="middle" justify="start">
                  <Radio.Group
                    defaultValue="cash"
                    buttonStyle="solid"
                    onChange={(e) => {
                      setPayment(e.target.value);
                    }}
                  >
                    <Radio.Button value="cash">Cash</Radio.Button>
                    <Radio.Button value="card">Debit/Credit Card</Radio.Button>
                    <Radio.Button value="credit">Credit Sales</Radio.Button>
                  </Radio.Group>
                </Row>
                {payment === "credit" && (
                  <div>
                    {credit ? (
                      <div className="d-flex">
                        <p>Number of Credit Due days: {credit}&nbsp;</p>
                        <EditFilled onClick={() => setShowCredit(true)} />
                      </div>
                    ) : (
                      <a
                        className="text-primary text-decoration-underline"
                        onClick={() => setShowCredit(true)}
                      >
                        Set Credit Due
                      </a>
                    )}
                  </div>
                )}
                <TextArea
                  placeholder="Comments"
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  className="my-2 w-100"
                  onChange={(e) => setComments(e?.target?.value)}
                />
                <Checkbox
                  className="mb-2"
                  onChange={(e) => setShow(e?.target?.checked)}
                >
                  <i>Show Comments on Receipt</i>
                </Checkbox>
                <div className="w-100 pb-4">
                  <Button
                    className="w-100 mt-2"
                    type="primary"
                    loading={loading}
                    onClick={() => handleSubmit(data?._id)}
                  >
                    Complete Sales
                  </Button>
                </div>
              </div>
            </Col>
          ) : (
            <Col span={8}>
              <div className="bg-white p-3">
                <Row align="middle" justify="space-between">
                  <Col>
                    <h6>Delivery Fee</h6>
                  </Col>
                  <Col>
                    <h6 className="d-flex align-items-center text-danger">
                      AED&nbsp;
                      <h6 className="text-danger mb-0">
                        {data?.deliverycharge?.toFixed(2) || 0.0}
                      </h6>
                    </h6>
                  </Col>
                </Row>
                <Row align="middle" justify="space-between">
                  <Col>
                    <h6>Total Amount</h6>
                  </Col>
                  <Col>
                    <h6 className="d-flex align-items-center text-success">
                      AED&nbsp;
                      <h4 className="text-success">
                        {data?.nettotal?.toFixed(2)}
                      </h4>
                    </h6>
                  </Col>
                </Row>
                <Divider className="my-2" />

                <Row
                  className="w-100 my-3"
                  align="middle"
                  justify="space-between"
                >
                  <Col>
                    <p>Status</p>
                  </Col>
                  <Col>
                    <Tag color="orange">{data?.status}</Tag>
                  </Col>
                </Row>
                <Divider className="my-2" />
                <Row
                  className="w-100 my-3"
                  align="middle"
                  justify="space-between"
                >
                  <Col>
                    <p>Payment Type</p>
                  </Col>
                  <Col>
                    <Tag color="magenta">{data?.paymenttype}</Tag>
                  </Col>
                </Row>
                <Divider className="my-2" />
                <div className="w-100 pb-4">
                  <Button
                    className="w-100 mt-2"
                    type="primary"
                    loading={loading}
                    onClick={() => handlePrint(data?._id)}
                  >
                    Print Invoice
                  </Button>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
      <Modal
        title="Set Credit Due days"
        visible={showCredit}
        onOk={() => setShowCredit(false)}
        onCancel={() => {
          setShowCredit(false);
          setCredit(0);
        }}
        size="small"
        width={250}
        destroyOnClose={true}
      >
        <div className="d-flex flex-column justify-content-center w-100  align-items-center">
          {/* <p>Enter Credit Due Days</p>
          <InputNumber
            onChange={(e) => setCredit(e)}
            max={100}
            defaultValue={credit}
          /> */}
          <Select
            placeholder="Select credit due days"
            defaultValue={credit}
            style={{ width: "100%" }}
            onChange={(e) => setCredit(e)}
          >
            <Option value="15">NET 15 Days</Option>
            <Option value="30">NET 30 Days</Option>
            <Option value="60">NET 60 Days</Option>
          </Select>
        </div>
      </Modal>
    </div>
  );
}

export default Invoice;
