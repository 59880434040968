import {
  LockOutlined,
  MailOutlined,
  MobileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, message } from "antd";
import Layout from "antd/lib/layout/layout";
import Logo from "assets/images/logo.jpeg";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "utils/constants";

import styles from "./register.module.scss";

const Register = () => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const onFinish = (values) => {
    setLoading(true);
    const data = JSON.stringify({
      username: values.username,
      email: values.email,
      phonenumber: values.mobilenumber,
      password: values.password,
    });

    const config = {
      method: "post",
      url: `${BASE_URL}/api/signup`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response) {
          message.success("Signup success!");
          setLoading(false);
          history.push("/");
        }
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
        setLoading(false);
      });
  };
  return (
    <Layout>
      <Col className={styles.container}>
        <Form
          name="normal_login"
          className={styles.loginForm}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item>
            <img src={Logo} alt="logo" className={styles.loginLogo} />
          </Form.Item>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username",
              },
            ]}
          >
            <Input
              type="text"
              className={styles.formInput}
              prefix={<UserOutlined className={styles.siteFormItemIcon} />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your valid Email!",
              },
            ]}
          >
            <Input
              type="email"
              className={styles.formInput}
              prefix={<MailOutlined className={styles.siteFormItemIcon} />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="mobilenumber"
            rules={[
              {
                required: true,
                message: "Please input your mobile number!",
              },
            ]}
          >
            <Input
              type="tel"
              className={styles.formInput}
              prefix={<MobileOutlined className={styles.siteFormItemIcon} />}
              placeholder="Mobile Number"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              className={styles.formInput}
              prefix={<LockOutlined className={styles.siteFormItemIcon} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginFormButton}
              loading={isLoading}
            >
              Sign up
            </Button>
            Or{" "}
            <a href="/" className={styles.register}>
              login
            </a>
          </Form.Item>
        </Form>
      </Col>
    </Layout>
  );
};

export default Register;
