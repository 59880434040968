/* eslint-disable react/prop-types */
import { CloseOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  message,
  Row,
  Space,
  Switch,
  TreeSelect,
  Upload,
} from "antd";
import { TreeNode } from "antd/lib/tree-select";
import axios from "axios";
import TagsInputComponet from "common/TagsInputComponet";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getUrlExtension, token } from "utils";
import { BASE_URL, Parent_Category } from "utils/constants";
import * as Yup from "yup";

import styles from "./EditCategory.module.scss";
function EditCategory({ data, setIsModalVisible, fetchCategory, categoryArr }) {
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState([]);
  const [thumbUrl, setThumbUrl] = useState([]);
  const [menuImageUrl, setMenuImageUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const onImageChange = (e) => setImageUrl(e?.fileList);
  const onThumbImageChange = (e) => setThumbUrl(e?.fileList);
  const onMenuImageChange = (e) => setMenuImageUrl(e?.fileList);

  const { TextArea } = Input;
  const CategorySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter the category name"),
    displayed: Yup.boolean(),
    description: Yup.string()
      .required("Please enter category description")
      .min(2, "Too Short!")
      .max(150, "Too Long!"),
    parentId: Yup.string().required("Please select a category option"),
    slug: Yup.string().required("Please enter category slug"),
  });
  useEffect(() => {
    if (data?.images) {
      setImageUrl([{ thumbUrl: BASE_URL + "/" + data?.images?.coverimage }]);
      setThumbUrl([{ thumbUrl: BASE_URL + "/" + data?.images?.thumbnail }]);
      setMenuImageUrl([
        { thumbUrl: BASE_URL + "/" + data?.images?.menuthumbnail },
      ]);
    }
    if (data?.keywords) {
      setTags(data?.keywords?.split(",").filter((tag) => tag != ""));
    }
  }, [data]);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleResetAll = (reset) => {
    reset();
    setImageUrl([]);
    setIsModalVisible(null);
  };
  const onSubmit = (submit) => {
    if (
      imageUrl?.length > 0 &&
      thumbUrl?.length > 0 &&
      menuImageUrl.length > 0
    ) {
      setLoading(true);
      submit();
    } else {
      message.error("Please attach image to continue");
    }
  };

  const imageAppendProcess = async (formdata) => {
    if (imageUrl?.[0]?.originFileObj) {
      await formdata.append("coverimage", imageUrl?.[0]?.originFileObj);
    } else {
      const url = imageUrl?.[0]?.thumbUrl;
      const imgExt = getUrlExtension(url);
      const fileName = `coverimage.${imgExt}`;

      await fetch(url).then(async (response) => {
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        formdata.append("coverimage", file);
      });
    }
    if (thumbUrl?.[0]?.originFileObj) {
      await formdata.append("thumbnail", thumbUrl?.[0]?.originFileObj);
    } else {
      const url = thumbUrl?.[0]?.thumbUrl;
      const imgExt = getUrlExtension(url);
      const fileName = `thumbnail.${imgExt}`;

      await fetch(url).then(async (response) => {
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        formdata.append("thumbnail", file);
      });
    }
    if (menuImageUrl?.[0]?.originFileObj) {
      await formdata.append("menuthumbnail", menuImageUrl?.[0]?.originFileObj);
    } else {
      const url = menuImageUrl?.[0]?.thumbUrl;
      const imgExt = getUrlExtension(url);
      const fileName = `menuthumbnail.${imgExt}`;

      await fetch(url).then(async (response) => {
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        formdata.append("menuthumbnail", file);
      });
    }

    return true;
  };

  const handleEdit = async (values) => {
    const FormData = require("form-data");
    const formdata = new FormData();

    await imageAppendProcess(formdata);
    let meta = "";
    tags.forEach((item) => (meta = meta + `${item},`));
    setTimeout(() => {
      formdata.append("name", values?.name);
      formdata.append("arName", values?.arName);
      formdata.append("isdisplayed", values?.displayed);
      formdata.append("description", values?.description);
      formdata.append("arDescription", values?.arDescription);
      formdata.append("slug", values?.slug);
      formdata.append("keywords", meta);
      formdata.append("parentid", values.parentId);
      const config = {
        method: "patch",
        url: `${BASE_URL}/api/categories/${data?.id}`,
        headers: {
          "x-auth-token": token(),
          "Content-Type": "multipart/form-data",
        },
        data: formdata,
      };

      axios(config)
        .then(function (response) {
          setLoading(false);
          fetchCategory();
          message.success(response?.data?.message);
          setImageUrl([]);
          setThumbUrl([]);
          setMenuImageUrl([]);
          setIsModalVisible(null);
          history.push("/category");
        })
        .catch(function (error) {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    }, 2000);
  };
  const showLeafIcon = false;
  const RootArr = (data) => {
    const Tree = data?.map((item) => (
      <TreeNode key={item?._id} value={item?._id} title={item?.name}>
        {RootArr(item?.subcategories)}
      </TreeNode>
    ));
    return Tree;
  };
  return (
    <div className={styles.EditCategoryWrapper}>
      <Formik
        initialValues={{
          name: data?.name,
          arName: data?.arName,
          displayed: data?.active,
          description: data?.description,
          arDescription: data?.arDescription,
          parentId: data?.parent || Parent_Category,
          slug: data?.slug,
        }}
        enableReinitialize={true}
        validationSchema={CategorySchema}
        onSubmit={(values) => {
          handleEdit(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          handleReset,
          setFieldValue,
          values,
        }) => (
          <>
            <Col>
              <Row align="middle" className="mb-4">
                <Col span={8}>
                  <p className="text-right">
                    Category Name in English{" "}
                    <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <Input
                    placeholder="Category Name In English"
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values.name}
                  />
                  <pre className="text-danger">
                    {errors.name && touched.name ? (
                      <div>{errors.name}</div>
                    ) : null}
                  </pre>
                </Col>
              </Row>
              <Row align="middle" className="mb-4">
                <Col span={8}>
                  <p className="text-right">
                    Category Name in Arabic{" "}
                    <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <Input
                    placeholder="Category Name In Arabic"
                    onChange={handleChange("arName")}
                    onBlur={handleBlur("arName")}
                    value={values.arName}
                  />
                  <pre className="text-danger">
                    {errors.arName && touched.arName ? (
                      <div>{errors.arName}</div>
                    ) : null}
                  </pre>
                </Col>
              </Row>
              <Row align="middle" className="mb-4">
                <Col span={8}>
                  <p className="text-right">
                    Parent category<span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <TreeSelect
                    treeLine={true && { showLeafIcon }}
                    style={{ width: "100%" }}
                    onSelect={(e) => setFieldValue("parentId", e)}
                    placeholder="Please select"
                    value={values.parentId}
                  >
                    <TreeNode value="61b853d2bc9754a4f5e5439c" title="Root">
                      {RootArr(categoryArr)}
                    </TreeNode>
                  </TreeSelect>
                  {/* <pre className="text-danger">
                    {errors.name && touched.name ? (
                      <div>{errors.name}</div>
                    ) : null}
                    </pre> */}
                </Col>
              </Row>
              <Row align="middle" className="mb-4">
                <Col span={8}>
                  <p className="text-right mb-0">
                    Displayed <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <Switch
                    defaultChecked
                    onChange={(e) => setFieldValue("displayed", e)}
                    // value={values.displayed}
                    checked={values.displayed}
                  />
                </Col>
              </Row>
              <Row align="top" className="mb-4">
                <Col span={8}>
                  <p className="text-right mb-0">
                    Description In English{" "}
                    <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <TextArea
                    rows={4}
                    placeholder="Description In Arabic"
                    onChange={handleChange("description")}
                    onBlur={handleBlur("description")}
                    value={values.description}
                  />

                  <pre className="text-danger">
                    {errors.description && touched.description ? (
                      <div>{errors.description}</div>
                    ) : null}
                  </pre>
                </Col>
              </Row>
              <Row align="top" className="mb-4">
                <Col span={8}>
                  <p className="text-right mb-0">
                    Description In Arabic <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <TextArea
                    rows={4}
                    placeholder="Description In Arabic"
                    onChange={handleChange("arDescription")}
                    onBlur={handleBlur("arDescription")}
                    value={values.arDescription}
                  />

                  <pre className="text-danger">
                    {errors.arDescription && touched.arDescription ? (
                      <div>{errors.arDescription}</div>
                    ) : null}
                  </pre>
                </Col>
              </Row>
              <Row align="middle" className="mb-4">
                <Col span={8}>
                  <p className="text-right mb-0">
                    Category Image <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <Row>
                    <Space>
                      <Col>
                        <p>Cover Image</p>
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={true}
                          onChange={(e) => onImageChange(e)}
                          accept="image/*"
                          fileList={imageUrl}
                          onSubmit={() => {}}
                          maxCount={1}
                        >
                          {uploadButton}
                        </Upload>
                      </Col>
                      <Col>
                        <p>Thumbnail</p>
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={true}
                          onChange={(e) => onThumbImageChange(e)}
                          accept="image/*"
                          fileList={thumbUrl}
                          onSubmit={() => {}}
                          maxCount={1}
                        >
                          {uploadButton}
                        </Upload>
                      </Col>
                      <Col>
                        <p>Menu Thumbnail</p>
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={true}
                          onChange={(e) => onMenuImageChange(e)}
                          accept="image/*"
                          fileList={menuImageUrl}
                          onSubmit={() => {}}
                          maxCount={1}
                        >
                          {uploadButton}
                        </Upload>
                      </Col>
                    </Space>
                  </Row>
                </Col>
              </Row>
              <Row align="middle" className="mb-4">
                <Col span={8}>
                  <p className="text-right mb-0">
                    Meta Keywords <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <TagsInputComponet tags={tags} setTags={setTags} />
                </Col>
              </Row>
              <Row align="middle" className="mb-4">
                <Col span={8}>
                  <p className="text-right mb-0">
                    Friendly Slug <span className="text-danger">*</span>
                  </p>
                </Col>
                <Col span={15}>
                  <Input
                    placeholder="Friendly Slug"
                    onChange={handleChange("slug")}
                    onBlur={handleBlur("slug")}
                    value={values.slug}
                    className="mt-3"
                  />
                  <pre className="text-danger">
                    {errors.slug && touched.slug ? (
                      <div>{errors.slug}</div>
                    ) : null}
                  </pre>
                </Col>
              </Row>
            </Col>

            <Row align="middle" justify="center">
              <Space>
                <Button
                  type="secondary"
                  icon={<CloseOutlined />}
                  size="large"
                  onClick={() => handleResetAll(handleReset)}
                  className="d-flex align-items-center"
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  size="large"
                  onClick={() => onSubmit(handleSubmit)}
                  className="d-flex align-items-center"
                  loading={loading}
                >
                  Save
                </Button>
              </Space>
            </Row>
          </>
        )}
      </Formik>{" "}
    </div>
  );
}

export default EditCategory;
