// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Settings_SettingsWrapper__3uydD .Settings_heading__F37ou {\n  color: #ae674c;\n}\n.Settings_SettingsWrapper__3uydD .Settings_content__8S9dZ {\n  margin-top: 20px;\n  padding: 20px;\n  background-color: #fff;\n  border-radius: 15px;\n}\n.Settings_SettingsWrapper__3uydD .Settings_content__8S9dZ {\n  padding: 25px;\n}", "",{"version":3,"sources":["webpack://src/pages/Settings/Settings.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAEE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAAJ;AAEE;EACE,aAAA;AAAJ","sourcesContent":[".SettingsWrapper {\n  .heading {\n    color: #ae674c;\n  }\n  .content {\n    margin-top: 20px;\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 15px;\n  }\n  .content {\n    padding: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SettingsWrapper": "Settings_SettingsWrapper__3uydD",
	"heading": "Settings_heading__F37ou",
	"content": "Settings_content__8S9dZ"
};
export default ___CSS_LOADER_EXPORT___;
