import { Button, Result } from "antd";
import { useHistory } from "react-router-dom";

import styles from "./NotFound.module.scss";

function NotFound() {
  const history = useHistory();
  return (
    <div className={styles.NotFoundWrapper}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => history.push("/")}>
            Back Home
          </Button>
        }
      />
    </div>
  );
}

export default NotFound;
