import { DatePicker, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { useState } from "react";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./SaleReportDownloadModal.module.scss";

function SaleReportDownloadModal({ show, setShow }) {
  const { RangePicker } = DatePicker;
  const dateFormat = "DD/MM/YYYY";

  const [data, setData] = useState(null);

  const handleDate = (date) => {
    if (date)
      setData([
        moment(date?.[0]).format("YYYY-MM-DD"),
        moment(date?.[1]).format("YYYY-MM-DD"),
      ]);
  };

  const handleOk = () => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/reports/sales?startDate=${data?.[0]}&endDate=${data?.[1]}`,
      headers: {
        "x-auth-token": token(),
      },
      responseType: "blob",
    };

    axios(config)
      .then(function (response) {
        fileDownload(response.data, "sales-report.xlsx");
        message.success("sales-report.xlsx downloaded");
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
    setShow(false);
  };

  return (
    <div className={styles.SaleReportDownloadModalWrapper}>
      <Modal
        title="Download Sales Report"
        visible={show}
        onOk={handleOk}
        onCancel={() => setShow(false)}
        destroyOnClose
      >
        <div className="d-flex align-items-center justify-content-center w-100x">
          <RangePicker format={dateFormat} onChange={handleDate} />
        </div>
      </Modal>
    </div>
  );
}

export default SaleReportDownloadModal;
