import { DeleteFilled, EditFilled, PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, message, Space, Table } from "antd";
import confirm from "antd/lib/modal/confirm";
import Modal from "antd/lib/modal/Modal";
import AddStaff from "common/AddStaff";
import EditStaff from "common/EditStaff";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import PrivateAxios from "services/axiosService";
import { token } from "utils";

import styles from "./ManageStaff.module.scss";

function ManageStaff() {
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Manage Staffs</Breadcrumb.Item>
        <Breadcrumb.Item>All Staff</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Staffs</h3>
    </div>
  );
  const RightComponent = () => (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      size="large"
      className={styles.btn}
      onClick={() => setAddCustomer(true)}
    >
      Add Staff
    </Button>
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [addCustomer, setAddCustomer] = useState(false);
  const [editCustomer, setEditCustomer] = useState(null);

  const handleCloseModal = () => {
    listUsers();
    setAddCustomer(false);
  };

  const listUsers = () => {
    setLoading(true);
    const config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get("/api/staffs", config)
      .then(function (response) {
        setLoading(false);
        const arr = response?.data?.data?.reverse()?.map((item, i) => ({
          id: item?._id,
          key: i + 1,
          username: item?.name,
        }));

        setData(arr);
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    listUsers();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    const config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.delete(`/api/staffs/${id}`, config)
      .then(function () {
        setLoading(false);
        listUsers();
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "SI No.",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Name",
      dataIndex: "username",
      key: "username",
    },

    {
      title: "Action",
      key: "action",
      render: (data) => (
        <Space size="middle">
          <a
            className="d-flex align-items-center"
            onClick={() => setEditCustomer(data)}
          >
            Edit <EditFilled />
          </a>
          <a
            className="text-danger"
            onClick={() => {
              confirm({
                title: "Delete Staff",
                icon: <DeleteFilled />,
                content: "Are you sure delete this staff?",
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk() {
                  handleDelete(data?.id);
                },
                onCancel() {},
              });
            }}
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.ManageStaffWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />

      <div className="mt-3">
        <Table
          className="mt-3"
          dataSource={data}
          columns={columns}
          loading={loading}
        />
      </div>
      <Modal
        title="Edit Staff"
        visible={editCustomer !== null}
        onOk={() => setEditCustomer(null)}
        onCancel={() => {
          setEditCustomer(null);
        }}
        size="small"
        destroyOnClose={true}
        footer={null}
      >
        <EditStaff
          setAddCustomer={setEditCustomer}
          data={editCustomer}
          fetch={listUsers}
        />
      </Modal>
      <Modal
        title="Add New Customer"
        visible={addCustomer}
        onOk={() => setAddCustomer(false)}
        onCancel={() => {
          setAddCustomer(false);
        }}
        size="small"
        destroyOnClose={true}
        footer={null}
      >
        <AddStaff setAddCustomer={handleCloseModal} />
      </Modal>
    </div>
  );
}

export default ManageStaff;
