import {
  DownloadOutlined,
  EyeFilled,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  message,
  Modal,
  Select,
  Table,
  Upload,
} from "antd";
const { Option } = Select;
import Topbar from "common/Topbar";
import * as ExcelJs from "exceljs";
import { useFormik } from "formik";
import * as fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import PrivateAxios from "services/axiosService";
import { token } from "utils";
import * as Yup from "yup";

import styles from "./BulkUploadProducts.module.scss";

//formik initial values
const initialValues = {
  file: "",
  category: "",
};

//formik validation schema
const validationSchema = Yup.object().shape({
  file: Yup.string().required("Please select a file"),
  category: Yup.string().required("Please choose a category"),
});

const BulkUploadProducts = () => {
  const [processedData, setProcessedData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Fetch all categories
  const fetchCategories = () => {
    const config = {
      headers: {
        "x-auth-token": token(),
      },
    };
    PrivateAxios.get("/api/categories", config)
      .then((response) => {
        setCategories(response?.data?.data);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  //Generate bluk upload preview
  const generatePreview = (values) => {
    const promise = new Promise((resolve, reject) => {
      const data = [];
      const workbook = new ExcelJs.Workbook();
      const reader = new FileReader();
      reader.readAsArrayBuffer(values.file);
      reader.onload = async () => {
        const buffer = reader.result;
        await workbook.xlsx.load(buffer);
        const worksheet = workbook.getWorksheet("Products");
        worksheet.eachRow(function (row, rowNumber) {
          if (rowNumber !== 1) {
            data.push({
              code: row.values[1].toString(),
              name: row.values[2],
              price: parseFloat(row.values[3]),
              unit: row.values[4],
              image: row.values[5],
            });
          }
        });
        resolve(data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
    promise
      .then((d) => {
        setProcessedData(d);
        setIsBulkUploadOpen(true);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  //Bulk upload products
  const uploadProducts = () => {
    setIsLoading(true);
    const formData = new FormData();
    // console.log(formik.values);
    formData.append("file", formik.values.file);
    formData.append("categoryId", formik.values.category);
    const config = {
      headers: {
        "x-auth-token": token(),
        "Content-Type": "multipart/form-data",
      },
    };

    PrivateAxios.post("/api/bulk-upload", formData, config)
      .then((response) => {
        setIsLoading(false);
        message.success(response?.data?.message);
        setIsBulkUploadOpen(false);
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  //Formik config
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: generatePreview,
  });

  //Datasource for antd table
  const dataSource = processedData?.map((item) => ({
    key: item?.code,
    pCode: item?.code,
    name: item?.name,
    image: item?.image,
    price: item?.price,
    unit: item?.unit,
  }));

  //Columns for antd table
  const columns = [
    {
      title: "Code",
      dataIndex: "pCode",
      key: "pCode",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => {
        return name ? name : "-";
      },
      width: 250,
      align: "center",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (src) => {
        return src ? (
          <img src={src} alt="prod-img" className={styles.productImg} />
        ) : (
          "-"
        );
      },
      align: "center",
    },
    {
      title: "Unit",
      key: "unit",
      dataIndex: "unit",
      render: (unit) => {
        return unit ? unit : "-";
      },
      align: "center",
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (price) => {
        return price ? `AED ${price.toFixed(2)}` : "--";
      },
      align: "center",
    },
  ];

  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Catalog</Breadcrumb.Item>
        <Breadcrumb.Item>Products</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Bulk Upload</h3>
    </div>
  );

  //Download sample excel file
  const downloadExcelFile = async () => {
    const sampleData = [
      {
        code: "001",
        name: "Bread",
        price: 20,
        unit: "pcs",
        image:
          "https://www.kohinoor-joy.com/wp-content/uploads/2020/11/diwali-sweets.jpg",
      },
    ];
    try {
      const workbook = new ExcelJs.Workbook();
      const worksheet = workbook.addWorksheet("Products");
      worksheet.columns = [
        { header: "CODE", key: "code", width: 20 },
        { header: "NAME", key: "name", width: 20 },
        { header: "PRICE", key: "price", width: 20 },
        { header: "UNIT", key: "unit", width: 20 },
        { header: "IMAGE", key: "image", width: 50 },
      ];
      sampleData.forEach((data) => {
        worksheet.addRow(data);
      });
      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
      });
      const excelFile = await workbook.xlsx.writeBuffer("Products.xlsx");
      fileDownload(excelFile, "Products.xlsx");
    } catch (error) {
      message.error(error.message);
    }
  };
  const RightComponent = () => (
    <Button
      type="primary"
      icon={<DownloadOutlined />}
      size="large"
      className={styles.btn}
      onClick={downloadExcelFile}
    >
      Download File Format
    </Button>
  );

  return (
    <div className={styles.BulkUploadProductsWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className={styles.form}>
        <p>
          Select File<span className="text-danger">*</span>
        </p>
        <Upload
          accept=".xlsx,.xls"
          onChange={(e) => {
            formik.setFieldValue("file", e.fileList[0].originFileObj);
          }}
          onBlur={formik.handleBlur("file")}
          beforeUpload={() => false}
          maxCount={1}
          className="d-flex align-items-center flex-wrap gap-2"
        >
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            className={styles.btn2}
          >
            Choose
          </Button>
        </Upload>
        {formik.errors.file && formik.touched.file ? (
          <pre className="text-danger">{formik.errors.file}</pre>
        ) : null}
        <p className="mt-3">
          Select Category<span className="text-danger">*</span>
        </p>
        <Select
          size="large"
          style={{
            width: "100%",
          }}
          onChange={formik.handleChange("category")}
          onBlur={formik.handleBlur("category")}
          value={formik.values.category}
        >
          {categories?.map((category) => (
            <Option value={category._id} key={category._id}>
              {category.name}
            </Option>
          ))}
        </Select>
        {formik.errors.category && formik.touched.category ? (
          <pre className="text-danger">{formik.errors.category}</pre>
        ) : null}
        <div className="d-flex justify-content-center mt-5">
          <Button
            type="primary"
            icon={<EyeFilled />}
            size="large"
            className={styles.btn2}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Preview
          </Button>
        </div>
      </div>
      <Modal
        centered
        visible={isBulkUploadOpen}
        onCancel={() => {
          // !isLoading &&
          setIsBulkUploadOpen(false);
        }}
        width={1000}
        bodyStyle={{ height: 600 }}
        footer={[
          <Button
            key="back"
            size="large"
            onClick={() => setIsBulkUploadOpen(false)}
          >
            Cancel
          </Button>,
          <Button
            key="link"
            type="primary"
            size="large"
            icon={<UploadOutlined />}
            onClick={uploadProducts}
            loading={isLoading}
          >
            Upload
          </Button>,
        ]}
      >
        <h4 className={styles.heading}>Preview</h4>
        <hr className={styles.divider} />
        {processedData ? (
          <Table
            columns={columns}
            dataSource={dataSource}
            scroll={{ y: 400 }}
          />
        ) : null}
      </Modal>
    </div>
  );
};

export default BulkUploadProducts;
