import { DeleteFilled, EditFilled, PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, message, Popconfirm, Row, Tag } from "antd";
import { Space, Table } from "antd";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PrivateAxios from "services/axiosService";
import { token } from "utils";

import styles from "./Offer.module.scss";

function Offer() {
  const [isLoading, setLoading] = useState(true);
  const [result, setResult] = useState([]);

  const getProducts = () => {
    let config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get("/api/promo-codes", config)
      .then(function (response) {
        setResult(response?.data?.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const history = useHistory();
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Catalog</Breadcrumb.Item>
        <Breadcrumb.Item>Offers</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Offers</h3>
    </div>
  );
  const RightComponent = () => (
    <Row>
      <Space>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="large"
          className={styles.btn}
          onClick={() => history.push("/add-offers")}
        >
          Add Offer
        </Button>
      </Space>
    </Row>
  );

  const columns = [
    {
      title: "Offer ID",
      dataIndex: "_id",
      key: "_id",
      width: 150,
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 250,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text) => <b>{text}%</b>,
    },
    {
      title: "Min Order Amount",
      key: "minorderamount",
      dataIndex: "minorderamount",
      render: (text) => <b>AED {text}</b>,
    },
    {
      title: "Status",
      key: "status",
      render: (text) => {
        return text.status ? (
          <Tag color="#87d068">Active</Tag>
        ) : (
          <Tag color="#f50">Expired</Tag>
        );
      },
      width: 100,
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <Space size="middle">
          <EditFilled
            onClick={(e) => {
              e.stopPropagation();
              history.push("/edit-offers", item);
            }}
          />
          <Popconfirm
            title="Are you sure to delete this offer?"
            onConfirm={(e) => {
              e.stopPropagation();
              handleDelete(item?._id);
            }}
            onCancel={(e) => {
              e.stopPropagation();
            }}
            okText="Yes"
            cancelText="No"
          >
            <DeleteFilled
              className="text-danger"
              onClick={(e) => e.stopPropagation()}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const data = result?.map((item) => ({
    _id: item?._id,
    key: item?._id,
    title: item?.title,
    code: item?.code,
    discount: item?.discount,
    minorderamount: item?.minorderamount,
    status: item?.status,
  }));

  const handleDelete = (id) => {
    let config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.delete(`/api/promo-codes/${id}`, config)
      .then(function (response) {
        getProducts();
        setLoading(false);
        message.success(response?.data?.message);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <div className={styles.ProductWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />

      <div className="my-4">
        <Table
          columns={columns}
          pagination={data?.length > 20}
          loading={isLoading}
          dataSource={data}
        />
      </div>
    </div>
  );
}

export default Offer;
