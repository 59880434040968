import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  DatePicker,
  message,
  Modal,
  Row,
  Space,
  Table,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import AddProductionModal from "common/AddProductionModal";
import EditProductionModal from "common/EditProductionModal";
import Topbar from "common/Topbar";
import moment from "moment";
import { useState } from "react";
import PrivateAxios from "services/axiosService";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./Production.module.scss";

function Production() {
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Production</Breadcrumb.Item>
        <Breadcrumb.Item>Production Sheet</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Production Sheet</h3>
    </div>
  );

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(null);

  const RightComponent = () => (
    <Button
      type="primary"
      size="large"
      className={styles.btn}
      onClick={() => setShow(true)}
      icon={<PlusOutlined />}
    >
      Add Production
    </Button>
  );
  const { RangePicker } = DatePicker;
  const dateFormat = "DD/MM/YYYY";
  const [date, setDate] = useState(null);
  const [result, setResult] = useState([]);

  const handleDate = (data) => {
    if (data)
      setDate([
        moment(data?.[0]).format("YYYY-MM-DD"),
        moment(data?.[1]).format("YYYY-MM-DD"),
      ]);
  };

  const handleGenerate = (data) => {
    if (data?.length > 0) {
      const config = {
        headers: {
          "x-auth-token": token(),
        },
      };

      PrivateAxios.get(
        `/api/production-sheets?startDate=${data?.[0]}&endDate=${data?.[1]}`,
        config
      )
        .then(function (response) {
          setResult(response?.data?.data);
        })
        .catch(function (error) {
          message.error(
            error?.response?.data?.message || "Something went wrong!"
          );
        });
    } else {
      message.error("Choose a valid date");
    }
  };

  const handleDelete = (item) => {
    Modal.confirm({
      title: "Delete",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want delete ${item?.product?.name}?`,
      okText: "Sure",
      cancelText: "Cancel",
      onOk: () => {
        const config = {
          headers: {
            "x-auth-token": token(),
          },
        };

        PrivateAxios.delete(`/api/production-sheets/${item?._id}`, config)
          .then(function (response) {
            message.success(response?.data?.message || "Something went wrong!");
            handleGenerate(date);
          })
          .catch(function (error) {
            message.error(
              error?.response?.data?.message || "Something went wrong!"
            );
          });
      },
    });
  };

  const columns = [
    {
      title: "Product Code",
      key: "key",
      render: (data) => <span>{data?.product?.code || "---"}</span>,
    },
    {
      title: "Product",
      key: "product",
      render: (data) => (
        <Row>
          <Space>
            <Avatar src={`${BASE_URL}/${data?.product?.imagekeys?.[0]}`} />
            <span>{data?.product?.name || "---"}</span>
          </Space>
        </Row>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Edit",
      key: "edit",
      render: (data) => <Button onClick={() => setEdit(data)}>Edit</Button>,
    },
    {
      title: "Delete",
      key: "delete",
      render: (data) => (
        <Button type="primary" onClick={() => handleDelete(data)}>
          Delete
        </Button>
      ),
    },
  ];

  const refetch = () => {
    if (date) handleGenerate(date);
  };

  return (
    <div className={styles.ProductionWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className="d-flex align-items-center justify-content-center m-3">
        <Space>
          <RangePicker size="large" format={dateFormat} onChange={handleDate} />
          <Button
            type="primary"
            className="px-5"
            size="large"
            onClick={() => handleGenerate(date)}
          >
            Generate
          </Button>
        </Space>
      </div>
      <Table dataSource={result} columns={columns} />
      <AddProductionModal show={show} setShow={setShow} refetch={refetch} />
      <EditProductionModal
        show={edit !== null}
        data={edit}
        setShow={setEdit}
        refetch={refetch}
      />
    </div>
  );
}

export default Production;
