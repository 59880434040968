import { PrinterFilled } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  message,
  Modal,
  Row,
  Select,
  Steps,
  Table,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import PrintInvoice from "common/PrintInvoice";
import Topbar from "common/Topbar";
import moment from "moment";
import { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import PrivateAxios from "services/axiosService";
import { titleCase, token } from "utils";
import { BASE_URL } from "utils/constants";
const { Option } = Select;
import styles from "./OrderDetail.module.scss";

function OrderDetail({ history }) {
  const [data, setData] = useState(history?.location?.state?.data);

  const componentRef = useRef();
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/orders">Orders</Breadcrumb.Item>
        <Breadcrumb.Item>Order Details</Breadcrumb.Item>
        <Breadcrumb.Item>{data?.key}</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Order from {data?.user?.fullname}</h3>
    </div>
  );
  const RightComponent = () => <div />;

  const dataSource = data?.items?.map((item) => ({
    key: item?._id,
    img: BASE_URL + "/" + item?.image,
    ...item,
  }));

  const columns = [
    {
      title: "Products",
      key: "name",
      render: (data) => (
        <Row gutter={10}>
          <Col>
            <img src={data?.img} alt="product" className={styles.prodImg} />
          </Col>
          <Col>
            <p className={styles.prodName}>{data?.name}</p>
            <p className={styles.prodPrice}>AED {data?.price?.toFixed(2)}</p>
          </Col>
        </Row>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Price",
      key: "total",
      render: (data) => <span>AED {data?.total?.toFixed(2)}</span>,
    },
  ];

  const [visible, setVisible] = useState(false);
  const [isOrder, setOrder] = useState(true);
  const [isDelivery, setDelivery] = useState(true);
  const { Step } = Steps;
  const [orderStatus, setOrderStatus] = useState(data?.status);
  const [orderText, setOrderText] = useState("");

  const updateStatus = () => {
    const body = JSON.stringify({
      status: orderStatus,
      note: orderText,
    });

    const config = {
      headers: {
        "x-auth-token": token(),
        "Content-Type": "application/json",
      },
    };

    PrivateAxios.patch(`/api/orders/${data?.orderid}`, body, config)
      .then(function (response) {
        setOrderText("");
        const orderInfo = response?.data?.orderData;
        const payload = {
          key: orderInfo?._id,
          orderid: orderInfo?._id,
          products: orderInfo?.items?.length,
          time: moment(orderInfo?.orderdate)?.format("DD/MM/YY, hh:mm A"),
          buyer: orderInfo?.user?.fullname || orderInfo?.user,
          price: `AED ${orderInfo?.subtotal?.toFixed(2)}`,
          method: orderInfo?.paymenttype === "cod" ? "COD" : "Online",
          status: orderInfo?.status,
          ...orderInfo,
        };
        message.success(`Order status changed successfully`);
        setData(payload);
        setVisible(false);
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      });
  };
  const showConfirmModal = () => {
    setVisible(true);
  };

  return (
    <div className={styles.OrderDetailWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <Row className={styles.headerWrap} justify="space-between">
        <Col>
          <p className={styles.label}>Order Id</p>
          <p className={styles.id}>{data?.oid}</p>
        </Col>
        <Col>
          <p className={styles.label}>Products</p>
          <p className={styles.products}>{data?.items?.length}</p>
        </Col>
        <Col>
          <p className={styles.label}>Price</p>
          <p className={styles.success}>{data?.price}</p>
        </Col>
        <Col>
          <p className={styles.label}>Ordered On</p>
          <p className={styles.value}>{data?.time}</p>
        </Col>
        <Col>
          <p className={styles.label}>Delivered On</p>
          <p className={styles.value}>
            {data?.status === "delivered" ? data?.time : "Not yet delivered"}
          </p>
        </Col>
        <Col>
          <p className={styles.label}>Payment Mode</p>
          <p className={styles.value}>
            {data?.paymenttype === "cod" ? "COD" : "Online"}
          </p>
        </Col>
        <Col>
          <p className={styles.label}>Status</p>
          <p className={styles.success}>{data?.status || ""}</p>
        </Col>
        <Col>
          <ReactToPrint
            trigger={() => (
              <button className="border-0 p-2 rounded bg-light">
                <p className={styles.label}>Print Order</p>
                <PrinterFilled className={styles.products} />
              </button>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
      <Row justify="space-between" className="py-2">
        <Col span={16} className="bg-white p-3">
          <Table
            pagination={dataSource?.length > 10}
            dataSource={dataSource}
            columns={columns}
          />
          <div className="p-3 bg-light">
            <Row>
              <Col span={16}>
                <h6>Product Details</h6>
              </Col>
              <Col span={8}>
                <Row gutter={10} justify="space-between">
                  <Col>
                    <p className={styles.subLabel}>
                      Sub total ({dataSource?.length} items)
                    </p>
                  </Col>
                  <Col>
                    <p className={styles.subPrice}>
                      AED <strong>{data?.subtotal?.toFixed(2)}</strong>
                    </p>
                  </Col>
                </Row>
                {data?.promodata?.code && (
                  <Row gutter={10} justify="space-between">
                    <Col>
                      <p className={styles.subLabel}>Discount</p>
                    </Col>
                    <Col>
                      <p className={styles.subPrice}>
                        <strong>{data?.promodata?.discount}</strong>%
                      </p>
                    </Col>
                  </Row>
                )}
                <Row gutter={10} justify="space-between">
                  <Col>
                    <p className={styles.subLabel}>Delivery Fee</p>
                  </Col>
                  <Col>
                    <p className={styles.subPrice}>
                      AED <strong>{data?.deliverycharge}</strong>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider className="my-2" />

            <Row align="middle" justify="end">
              <Col>
                <Row gutter={20}>
                  <Col>
                    <p className="text-success mb-0">
                      <strong>Total Paid</strong>
                    </p>
                  </Col>
                  <Col>
                    <p className="text-success mb-0">
                      AED <strong>{data?.nettotal?.toFixed(2)}</strong>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={7} className="bg-white w-25">
          <Row>
            <Col span={12}>
              <Button
                className={isOrder ? styles.tab : styles.tabActive}
                type="text"
                onClick={() => setOrder(true)}
              >
                <strong>Change Order Status</strong>
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className={isOrder ? styles.tabActive : styles.tab}
                type="text"
                onClick={() => setOrder(false)}
              >
                <strong>Order History</strong>
              </Button>
            </Col>
          </Row>
          <div className="px-4 py-3">
            {isOrder ? (
              <div>
                <Select
                  className="w-100"
                  defaultValue={data?.status}
                  onChange={(e) => setOrderStatus(e)}
                >
                  <Option value="placed">Placed</Option>
                  {/* <Option value="packed">Packed</Option>
                  <Option value="shipped">Shipped</Option> */}
                  <Option value="processing">Processing</Option>
                  <Option value="delivered">Delivered</Option>
                  <Option value="cancelled">Cancelled</Option>
                </Select>
                <TextArea
                  rows={3}
                  placeholder="Add Customer Note"
                  className="my-2"
                  onChange={(e) => setOrderText(e?.target?.value)}
                  value={orderText}
                />
                <Button
                  type="primary"
                  className="w-100"
                  onClick={showConfirmModal}
                >
                  Update Order Status
                </Button>
                <Modal
                  title="Update Order Status"
                  visible={visible}
                  onOk={updateStatus}
                  onCancel={() => {
                    setVisible(false);
                  }}
                >
                  <p>
                    Are you sure want to change the order status to{" "}
                    <b>{orderStatus}</b> ?
                  </p>
                </Modal>
                <Divider className="my-3" />
                <Row align="middle" justify="space-between">
                  <Col>Buyer Details</Col>
                  <Col />
                </Row>
                <Divider className="my-2" />
                <Row gutter={10}>
                  <Col span={4}>
                    <p className={styles.title}>Name</p>
                  </Col>
                  <Col>
                    <p className={styles.titleName}>{data?.user?.fullname}</p>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={4}>
                    <p className={styles.title}>Email</p>
                  </Col>
                  <Col>
                    <p className={styles.titleName}>{data?.user?.email}</p>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={4}>
                    <p className={styles.title}>Phone</p>
                  </Col>
                  <Col>
                    <p className={styles.titleName}>
                      {data?.user?.phonenumber}
                    </p>
                  </Col>
                </Row>
                <Divider className="my-3" />
                <div className="p-2 bg-light">
                  <Row>
                    <Col span={12}>
                      <Button
                        className={isDelivery ? styles.tabActive : styles.tab}
                        type="text"
                        onClick={() => setDelivery(true)}
                      >
                        Shipping Address
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        className={isDelivery ? styles.tab : styles.tabActive}
                        type="text"
                        onClick={() => setDelivery(false)}
                      >
                        Billing Address
                      </Button>
                    </Col>
                  </Row>
                  {isDelivery ? (
                    <div className="p-3">
                      {data?.addresses?.delivery?.fullname}
                      <br />
                      {data?.addresses?.delivery?.address} <br />{" "}
                      {data?.addresses?.delivery?.building} <br />
                      {data?.addresses?.delivery?.locality}
                      <br />
                      {data?.addresses?.delivery?.landmark}
                      <br />
                      {data?.addresses?.delivery?.emirates},
                      {data?.addresses?.delivery?.phonenumber}
                    </div>
                  ) : (
                    <div className="p-3">
                      {data?.addresses?.billing?.fullname}
                      <br />
                      {data?.addresses?.billing?.address} <br />{" "}
                      {data?.addresses?.billing?.building} <br />
                      {data?.addresses?.billing?.locality}
                      <br />
                      {data?.addresses?.billing?.landmark}
                      <br />
                      {data?.addresses?.billing?.emirates},
                      {data?.addresses?.billing?.phonenumber}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <Steps
                progressDot
                current={data?.updates?.length - 1}
                direction="vertical"
              >
                <Steps
                  progressDot
                  current={data?.updates?.length - 1}
                  direction="vertical"
                >
                  {data?.updates?.map((item) => (
                    <Step
                      key={item?.status}
                      title={titleCase(item?.status)}
                      description={`${moment(item?.date).format(
                        "DD-MM-YY, hh:ss A"
                      )}, ${item?.note || ""}`}
                    />
                  ))}
                </Steps>
              </Steps>
            )}
          </div>
        </Col>
      </Row>
      <div className="d-none">
        <PrintInvoice ref={componentRef} tableData={dataSource} data={data} />
      </div>
    </div>
  );
}

export default OrderDetail;
