// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserDetails_UserDetailsWrapper__uING4 .UserDetails_heading__3Zt53 {\n  color: #ae674c;\n}\n.UserDetails_UserDetailsWrapper__uING4 .UserDetails_subheading__3eRv2 {\n  color: #afafaf;\n}\n.UserDetails_UserDetailsWrapper__uING4 thead[class*=ant-table-thead] th {\n  background-color: #fff !important;\n}\n.UserDetails_UserDetailsWrapper__uING4 .UserDetails_btn__2y72- {\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://src/pages/UserDetails/UserDetails.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAEE;EACE,cAAA;AAAJ;AAEE;EACE,iCAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;AAAJ","sourcesContent":[".UserDetailsWrapper {\n  .heading {\n    color: #ae674c;\n  }\n  .subheading {\n    color: #afafaf;\n  }\n  thead[class*=\"ant-table-thead\"] th {\n    background-color: #fff !important;\n  }\n  .btn {\n    display: flex;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserDetailsWrapper": "UserDetails_UserDetailsWrapper__uING4",
	"heading": "UserDetails_heading__3Zt53",
	"subheading": "UserDetails_subheading__3eRv2",
	"btn": "UserDetails_btn__2y72-"
};
export default ___CSS_LOADER_EXPORT___;
