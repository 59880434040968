import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Breadcrumb, message, Switch } from "antd";
import Topbar from "common/Topbar";
import { useEffect, useState } from "react";
import PrivateAxios from "services/axiosService";
import { token } from "utils";

import styles from "./Settings.module.scss";

function Settings() {
  const RightComponent = () => <div />;
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Settings</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Settings</h3>
    </div>
  );

  const [isForceUpdate, setForceUpdate] = useState(false);
  const checkForForceUpdate = () => {
    PrivateAxios.get("/api/config")
      .then(function (response) {
        setForceUpdate(response?.data?.data?.forceupdate);
      })
      .catch(function (error) {
        message.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    checkForForceUpdate();
  }, []);

  const handleUpdate = (value) => {
    const data = JSON.stringify({
      forceupdate: value,
    });

    const config = {
      headers: {
        "x-auth-token": token(),
        "Content-Type": "application/json",
      },
    };

    PrivateAxios.post("/api/config", data, config)
      .then(function (response) {
        setForceUpdate(value);
        message.success(response?.data?.message);
      })
      .catch(function (error) {
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <div className={styles.SettingsWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className={styles.content}>
        <h5>Enable Force Update in Mobile App</h5>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(val) => handleUpdate(val)}
          checked={isForceUpdate}
        />
      </div>
    </div>
  );
}

export default Settings;
