import { CloseOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  message,
  Radio,
  Row,
  Space,
  TreeSelect,
  Upload,
} from "antd";
import TagsInputComponet from "common/TagsInputComponet";
import Topbar from "common/Topbar";
import { Formik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import PrivateAxios from "services/axiosService";
import { token } from "utils";
import { Parent_Category } from "utils/constants";
import * as Yup from "yup";

import styles from "./AddCategory.module.scss";

function AddCategory() {
  const [tags, setTags] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [thumbUrl, setThumbUrl] = useState([]);
  const [menuImageUrl, setMenuImageUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const categoryArr = history?.location?.state?.data;

  const onImageChange = (e) => {
    setImageUrl(e?.fileList);
  };

  const onThumbImageChange = (e) => setThumbUrl(e?.fileList);
  const onMenuImageChange = (e) => setMenuImageUrl(e?.fileList);

  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/products">Catalog</Breadcrumb.Item>
        <Breadcrumb.Item href="/category">Category</Breadcrumb.Item>
        <Breadcrumb.Item> Add Category</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Add Category</h3>
    </div>
  );
  const RightComponent = () => <div />;
  const { TextArea } = Input;
  const { TreeNode } = TreeSelect;
  const CategorySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter the category name"),
    displayed: Yup.boolean(),
    description: Yup.string()
      .required("Please enter category description")
      .min(2, "Too Short!")
      .max(150, "Too Long!"),
    parentId: Yup.string().required("Please select a category option"),
    slug: Yup.string().required("Please enter category slug"),
  });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleResetAll = (reset) => {
    reset();
    setImageUrl(null);
  };
  const onSubmit = (submit) => {
    if (imageUrl?.length > 0) {
      submit();
    } else {
      message.error("Please attach image to continue");
    }
  };

  const options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const showLeafIcon = false;

  const RootArr = (data) => {
    const Tree = data?.map((item) => (
      <TreeNode key={item?._id} value={item?._id} title={item?.name}>
        {RootArr(item?.subcategories)}
      </TreeNode>
    ));
    return Tree;
  };

  return (
    <div className={styles.AddCategoryWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />

      <div className={styles.content}>
        {/* <h5 className="mb-1">Add New Category</h5> */}
        {/* <Divider className="my-2" /> */}
        <Formik
          initialValues={{
            name: "",
            arName: "",
            displayed: true,
            description: "",
            arDescription: "",
            parentId: Parent_Category,
            slug: "",
          }}
          validationSchema={CategorySchema}
          onSubmit={(values) => {
            if (!imageUrl || !thumbUrl || !menuImageUrl) {
              message.error("Please add images");
              return;
            }

            if (tags?.length === 0) {
              message.error("Please add keywords");
              return;
            }
            let meta = "";
            tags.forEach((item) => (meta = meta + `${item},`));
            setLoading(true);
            let FormData = require("form-data");
            let data = new FormData();
            data.append("name", values.name);
            data.append("arName", values.arName);
            data.append("isdisplayed", values.displayed);
            data.append("description", values.description);
            data.append("arDescription", values.arDescription);
            data.append("coverimage", imageUrl?.[0]?.originFileObj);
            data.append("thumbnail", thumbUrl?.[0]?.originFileObj);
            data.append("menuthumbnail", menuImageUrl?.[0]?.originFileObj);
            data.append("parentid", values.parentId);
            data.append("slug", values.slug);
            data.append("keywords", meta);

            let config = {
              headers: {
                "x-auth-token": token(),
                "Content-Type": "multipart/form-data",
              },
            };

            PrivateAxios.post("/api/categories", data, config)
              .then(function (response) {
                setLoading(false);
                message.success(response?.data?.message);
                history.push("/category");
              })
              .catch(function (error) {
                setLoading(false);
                message.error(error?.response?.data?.message);
              });
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            handleReset,
            setFieldValue,
            values,
          }) => (
            <>
              <Col className="p-3">
                <Row align="middle" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right  mb-0">
                      Category Name{" "}
                      <span style={{ color: "gray", fontSize: "12px" }}>
                        (english)
                      </span>
                      <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <Input
                      placeholder="Category Name in English"
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                      value={values.name}
                      className="mt-3"
                    />
                    <pre className="text-danger">
                      {errors.name && touched.name ? (
                        <div>{errors.name}</div>
                      ) : null}
                    </pre>
                  </Col>
                </Row>
                <Row align="middle" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right mb-0">
                      Category Name{" "}
                      <span style={{ color: "gray", fontSize: "12px" }}>
                        (arabic)
                      </span>
                      <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <Input
                      placeholder="Category Name in Arabic"
                      onChange={handleChange("arName")}
                      onBlur={handleBlur("arName")}
                      value={values.arName}
                      className="mt-3"
                    />
                    <pre className="text-danger">
                      {errors.arName && touched.arName ? (
                        <div>{errors.arName}</div>
                      ) : null}
                    </pre>
                  </Col>
                </Row>
                <Row align="middle" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right mb-0">
                      Displayed <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <Radio.Group
                      options={options}
                      onChange={(e) =>
                        setFieldValue("displayed", e?.target?.value)
                      }
                      value={values.displayed}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Col>
                </Row>

                <Row align="middle" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right mb-0">
                      Parent Category <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <TreeSelect
                      treeLine={true && { showLeafIcon }}
                      style={{ width: "100%" }}
                      onSelect={(e) => setFieldValue("parentId", e)}
                      placeholder="Please select"
                    >
                      <TreeNode value={Parent_Category} title="Root">
                        {RootArr(categoryArr)}
                      </TreeNode>
                    </TreeSelect>
                    <pre className="text-danger">
                      {errors.parentId ? <div>{errors.parentId}</div> : null}
                    </pre>
                  </Col>{" "}
                </Row>
                <Row align="top" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right mb-0">
                      Description In English{" "}
                      <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <TextArea
                      rows={4}
                      placeholder="Description in English"
                      onChange={handleChange("description")}
                      onBlur={handleBlur("description")}
                      value={values.description}
                    />

                    <pre className="text-danger">
                      {errors.description && touched.description ? (
                        <div>{errors.description}</div>
                      ) : null}
                    </pre>
                  </Col>
                </Row>
                <Row align="top" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right mb-0">
                      Description In Arabic
                      <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <TextArea
                      rows={4}
                      placeholder="Description in Arabic"
                      onChange={handleChange("arDescription")}
                      onBlur={handleBlur("arDescription")}
                      value={values.arDescription}
                    />

                    <pre className="text-danger">
                      {errors.arDescription && touched.arDescription ? (
                        <div>{errors.arDescription}</div>
                      ) : null}
                    </pre>
                  </Col>
                </Row>
                <Row align="middle" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right mb-0">
                      Images <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <Row justify="space-between">
                      <Col>
                        <p className="mb-1">Cover Image</p>
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={true}
                          onChange={(e) => onImageChange(e)}
                          accept="image/*"
                          fileList={imageUrl}
                          onSubmit={() => {}}
                          maxCount={1}
                          onRemove={() => setImageUrl([])}
                        >
                          {uploadButton}
                        </Upload>
                      </Col>
                      <Col>
                        <p className="mb-1">Thumbnail</p>
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={true}
                          onChange={(e) => onThumbImageChange(e)}
                          accept="image/*"
                          fileList={thumbUrl}
                          onSubmit={() => {}}
                          maxCount={1}
                          onRemove={() => setThumbUrl([])}
                        >
                          {uploadButton}
                        </Upload>
                      </Col>
                      <Col>
                        <p className="mb-1">Menu Thumbnail</p>
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={true}
                          onChange={(e) => onMenuImageChange(e)}
                          accept="image/*"
                          fileList={menuImageUrl}
                          onSubmit={() => {}}
                          maxCount={1}
                          onRemove={() => setMenuImageUrl([])}
                        >
                          {uploadButton}
                        </Upload>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row align="middle" justify="center" className="mb-1">
                  <Col span={4}>
                    <p className="text-right mb-0">Meta Keywords</p>
                  </Col>
                  <Col span={8}>
                    <TagsInputComponet tags={tags} setTags={setTags} />
                  </Col>
                </Row>
                <Row align="middle" justify="center" className="mb-4">
                  <Col span={4}>
                    <p className="text-right mb-0">
                      Friendly Slug <span className="text-danger">*</span>
                    </p>
                  </Col>
                  <Col span={8}>
                    <Input
                      placeholder="Friendly Slug"
                      onChange={handleChange("slug")}
                      onBlur={handleBlur("slug")}
                      value={values.slug}
                      className="mt-3"
                    />
                    <pre className="text-danger">
                      {errors.slug && touched.slug ? (
                        <div>{errors.slug}</div>
                      ) : null}
                    </pre>
                  </Col>
                </Row>
              </Col>
              <Row align="middle" justify="center">
                <Space>
                  <Button
                    type="secondary"
                    icon={<CloseOutlined />}
                    size="large"
                    onClick={() => handleResetAll(handleReset)}
                    className="d-flex align-items-center"
                  >
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    size="large"
                    onClick={() => onSubmit(handleSubmit)}
                    className="d-flex align-items-center"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Space>
              </Row>
            </>
          )}
        </Formik>{" "}
      </div>
    </div>
  );
}

export default AddCategory;
