import { SaveOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Input, message } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Topbar from "common/Topbar";
import { Formik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import PrivateAxios from "services/axiosService";
import { token } from "utils";
import * as Yup from "yup";

import styles from "./EditOffer.module.scss";

function EditOffer() {
  const history = useHistory();
  const data = history?.location?.state;
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/products">Catalog</Breadcrumb.Item>
        <Breadcrumb.Item href="/products">Offers</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}> Edit Offers</h3>
    </div>
  );

  const RightComponent = () => <div />;

  const ProductSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, "Too Short!")
      .max(250, "Too Long!")
      .required("Please enter the offer title"),
    code: Yup.string()
      .required("Please enter offer code")
      .max(1250, "Too Long!"),
    discount: Yup.number("Enter valid discount").required(
      "Enter offer discount"
    ),
    minorderamount: Yup.number("Enter valid minimum order amount").required(
      "Enter minimum order amount"
    ),
    isActive: Yup.bool(),
  });

  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.EditOfferWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className="d-flex justify-content-between">
        <div className={styles.content}>
          <Formik
            initialValues={{
              title: data?.title,
              code: data?.code,
              discount: data?.discount,
              minorderamount: data?.minorderamount,
              isActive: data?.status,
            }}
            validationSchema={ProductSchema}
            onSubmit={(values) => {
              setLoading(true);
              let jsonData = {
                title: values.title,
                code: values.code,
                discount: values.discount,
                minorderamount: values.minorderamount,
                status: values.isActive,
              };

              const config = {
                headers: {
                  "x-auth-token": token(),
                },
              };

              PrivateAxios.patch(
                `/api/promo-codes/${data?._id}`,
                jsonData,
                config
              )
                .then(function (response) {
                  setLoading(false);
                  message.success(response?.data?.message);
                  history.push("/offers");
                })
                .catch(function (error) {
                  message.error(error?.response?.data?.message);
                  setLoading(false);
                });
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
              values,
            }) => (
              <>
                <div className="mb-3">
                  <p>
                    Offer Title<span className="text-danger">*</span>
                  </p>
                  <Input
                    placeholder="Offer title"
                    onChange={handleChange("title")}
                    onBlur={handleBlur("title")}
                    value={values.title}
                  />{" "}
                  {errors.title && touched.title ? (
                    <pre className="text-danger">{errors.title}</pre>
                  ) : null}
                </div>

                <div className="mb-3">
                  <p>
                    Code<span className="text-danger">*</span>
                  </p>
                  <Input
                    placeholder="Offer code"
                    onChange={handleChange("code")}
                    onBlur={handleBlur("code")}
                    value={values.code}
                  />{" "}
                  {errors.code && touched.code ? (
                    <pre className="text-danger">{errors.code}</pre>
                  ) : null}
                </div>
                <div className="mb-3">
                  <p>
                    Discount<span className="text-danger">*</span>
                  </p>
                  <Input
                    addonBefore="%"
                    placeholder="10%"
                    onChange={handleChange("discount")}
                    onBlur={handleBlur("discount")}
                    value={values.discount}
                  />{" "}
                  {errors.discount && touched.discount ? (
                    <pre className="text-danger">{errors.discount}</pre>
                  ) : null}
                </div>
                <div className="mb-3">
                  <p>
                    Min. Order Amount<span className="text-danger">*</span>
                  </p>
                  <Input
                    addonBefore="AED"
                    placeholder="Minimum order amount for this offer"
                    onChange={handleChange("minorderamount")}
                    onBlur={handleBlur("minorderamount")}
                    value={values.minorderamount}
                  />{" "}
                  {errors.minorderamount && touched.minorderamount ? (
                    <pre className="text-danger">{errors.minorderamount}</pre>
                  ) : null}
                </div>

                <Checkbox
                  defaultChecked={data?.status}
                  onChange={(e) =>
                    setFieldValue("isActive", e?.target?.checked)
                  }
                >
                  Is Active?
                </Checkbox>

                <div className="w-100 d-flex align-items-center justify-content-center">
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    icon={<SaveOutlined />}
                    size="large"
                    loading={loading}
                    className="d-flex align-items-center"
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default EditOffer;
