// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Topbar_TopbarWrapper__1mFP- {\n  background-color: #fff;\n  margin-top: -30px;\n  margin-left: -30px;\n  border-bottom: 0.5px solid #fafafc;\n  border-top: 1px solid #fafafc;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px 30px;\n}", "",{"version":3,"sources":["webpack://src/common/Topbar/Topbar.module.scss"],"names":[],"mappings":"AAAA;EAEE,sBAAA;EAGA,iBAAA;EACA,kBAAA;EACA,kCAAA;EACA,6BAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AAFF","sourcesContent":[".TopbarWrapper {\n  // position: absolute;\n  background-color: #fff;\n  // top: 50px;\n  // left: 232px;\n  margin-top: -30px;\n  margin-left: -30px;\n  border-bottom: 0.5px solid #fafafc;\n  border-top: 1px solid #fafafc;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TopbarWrapper": "Topbar_TopbarWrapper__1mFP-"
};
export default ___CSS_LOADER_EXPORT___;
