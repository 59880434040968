// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Production_ProductionWrapper__1li3z .Production_heading__Tdl1W {\n  color: #ae674c;\n}\n.Production_ProductionWrapper__1li3z thead[class*=ant-table-thead] th {\n  background-color: #fff !important;\n}\n.Production_ProductionWrapper__1li3z .Production_btn__3GD-k {\n  background-color: #d02130;\n  border-color: #d02130;\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://src/pages/Production/Production.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAEE;EACE,iCAAA;AAAJ;AAGE;EACE,yBAAA;EACA,qBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AADJ","sourcesContent":[".ProductionWrapper {\n  .heading {\n    color: #ae674c;\n  }\n  thead[class*=\"ant-table-thead\"] th {\n    background-color: #fff !important;\n  }\n\n  .btn {\n    background-color: #d02130;\n    border-color: #d02130;\n    border-radius: 5px;\n    display: flex;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProductionWrapper": "Production_ProductionWrapper__1li3z",
	"heading": "Production_heading__Tdl1W",
	"btn": "Production_btn__3GD-k"
};
export default ___CSS_LOADER_EXPORT___;
