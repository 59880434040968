import { DeleteFilled, EditFilled } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  message,
  Popconfirm,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import Text from "antd/lib/typography/Text";
import Topbar from "common/Topbar";
import PrivateAxios from "services/axiosService";
import { token } from "utils";
import { BASE_URL } from "utils/constants";

import styles from "./ProductDetail.module.scss";

function ProductDetail({ history }) {
  const product = history?.location?.state?.record;

  const handleDelete = (id) => {
    let config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.delete(`/api/products/${id}`, config)
      .then(function (response) {
        history.push("/products");
        message.success(response?.data?.message);
      })
      .catch(function (error) {
        message.error(error?.response?.data?.message);
      });
  };

  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Catalog</Breadcrumb.Item>
        <Breadcrumb.Item href="/products">Products</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Products Details</h3>
    </div>
  );
  const RightComponent = () => (
    <Row>
      <Space>
        <Col>
          <Button
            icon={<EditFilled />}
            className="w-100 mx-3 d-flex align-items-center justify-content-center"
            onClick={() => history.push("edit-products", product)}
          >
            Edit
          </Button>
        </Col>
        <Col>
          <Popconfirm
            title="Are you sure to delete this product?"
            onConfirm={(e) => {
              e.stopPropagation();
              handleDelete(product?._id);
            }}
            onCancel={(e) => {
              e.stopPropagation();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              icon={<DeleteFilled />}
              className="w-100 mx-3 d-flex align-items-center justify-content-center text-danger border-danger"
            >
              Delete
            </Button>
          </Popconfirm>
        </Col>
      </Space>
    </Row>
  );

  const { Title } = Typography;

  return (
    <div className={styles.ProductDetailWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className="w-100 h-100 my-3 p-3 bg-white">
        <Title level={3} className={styles.heading}>
          {product?.name}
        </Title>
        <Row className="mb-2">
          <Text strong>ID :</Text>
          <Text className="ms-2"> {product?._id}</Text>
        </Row>
        <Row className="mb-2">
          <Text strong>Code :</Text>
          <Text className="ms-2"> {product?.code}</Text>
        </Row>
        <Row gutter={30}>
          <Col className="mb-3">
            <div className="d-flex flex-column">
              <Text strong>Main image</Text>
              <Avatar
                shape="square"
                size={100}
                src={`${BASE_URL}/${product?.images?.mainimage}`}
              />
            </div>
          </Col>
          <Col className="mb-3">
            <Text strong>More images</Text>
            <Row gutter={10}>
              {product?.images?.gallery?.map((item) => (
                <Col key={item}>
                  <Avatar
                    shape="square"
                    size={100}
                    src={`${BASE_URL}/${item}`}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <Text strong>Small Description</Text>
        <br />
        <Text type="secondary">{product?.smalldescription}</Text>

        <Divider className="my-2" />
        <Row align="middle" justify="space-around">
          <Col>
            <Text>Quantity</Text>
            <br />
            <Text strong className={styles.heading}>
              {product?.quantity}
            </Text>
          </Col>
          <Col>
            <Text>Product price</Text>
            <br />
            <Text strong className={styles.heading}>
              AED {`${product?.price?.toFixed(2)}`}
            </Text>
          </Col>
          <Col>
            <Text>Unit</Text>
            <br />
            <Text strong className={styles.heading}>
              {product?.unit === "pckts"
                ? "Packets"
                : product?.unit === "pieces"
                ? "Pieces"
                : "Trays"}
            </Text>
          </Col>
          <Col>
            <Text>Category</Text>
            <br />
            <Text strong className={styles.heading}>
              {product?.categories?.map((item) => (
                <Tag color="red" key={item?._id}>
                  {item?.name}
                </Tag>
              ))}
            </Text>
          </Col>
        </Row>
        <Divider className="my-2" />
        <Text strong>Description</Text>
        <br />
        <Text type="secondary">{product?.description}</Text>
        <br />
        <Text strong>For SEO</Text>
        <br />
        <Text>Meta Name</Text>
        <br />
        <Text type="secondary">{product?.seo?.metaname}</Text>
        <Divider className="my-2" />
        <Text>Meta Tag</Text>
        <br />
        {product?.seo?.metatags?.map((item) => (
          <Tag color="red" key={item}>
            {item}
          </Tag>
        ))}
      </div>
    </div>
  );
}

export default ProductDetail;
