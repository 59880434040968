// import { PrinterFilled } from "@ant-design/icons";
import { Breadcrumb, Button, message, Table } from "antd";
import Topbar from "common/Topbar";
import moment from "moment";
import { useEffect, useState } from "react";
import PrivateAxios from "services/axiosService";
import { titleCase, token } from "utils";

import styles from "../Orders/Orders.module.scss";

function ProcessedOrders({ history }) {
  const LeftComponent = () => (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Orders</Breadcrumb.Item>
        <Breadcrumb.Item>Processed Orders</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.heading}>Processed Orders</h3>
    </div>
  );
  const RightComponent = () => <div />;

  const [ordersArr, setOrders] = useState([]);
  const dataSource = ordersArr?.map((item) => ({
    key: item?._id,
    orderid: item?._id,
    products: item?.items?.length,
    time: moment(item?.orderdate)?.format("DD/MM/YY, hh:mm A"),
    buyer: item?.user?.fullname || item?.user?.phonenumber,
    price: `AED ${item?.nettotal?.toFixed(2)}`,
    method: item?.paymenttype === "cod" ? "COD" : "Online",
    status: titleCase(item?.status),
    ...item,
  }));

  const columns = [
    {
      title: "Order ID",
      key: "orderid",
      render: (data) => <p className={styles.key}>{data?.oid}</p>,
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      align: "center",
    },
    {
      title: "Date & Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
    },
    {
      title: "Payment Method",
      dataIndex: "method",
      key: "method",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    // {
    //   title: "Print",
    //   key: "print",
    //   render: () => <Button type="link" icon={<PrinterFilled />} />,
    //   align: "center",
    // },
    {
      title: "View",
      key: "view",
      render: (data) => (
        <Button
          type="ghost"
          className="border"
          onClick={() => history.push(`/order/${data?.key}`, { data })}
        >
          View
        </Button>
      ),
      align: "center",
    },
  ];

  const [loading, setLoading] = useState(true);
  const getOrders = () => {
    setLoading(true);
    const config = {
      headers: {
        "x-auth-token": token(),
      },
    };

    PrivateAxios.get("/api/orders?status=processing", config)
      .then(function (response) {
        setOrders(response?.data?.data);
        setLoading(false);
      })
      .catch(function (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <div className={styles.OrdersWrapper}>
      <Topbar LeftComponent={LeftComponent} RightComponent={RightComponent} />
      <div className="py-1 mt-4">
        <Table dataSource={dataSource} columns={columns} loading={loading} />
      </div>
    </div>
  );
}

export default ProcessedOrders;
