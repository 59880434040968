/* eslint-disable react/prop-types */
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Divider, Input, message, Row, Space } from "antd";
import axios from "axios";
import { Formik } from "formik";
import { useState } from "react";
import { token } from "utils";
import { BASE_URL } from "utils/constants";
import * as Yup from "yup";

import styles from "./AddCustomer.module.scss";

function AddStaff({ setAddCustomer }) {
  const CustomerSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Enter customer name"),
  });
  const [loading, setLoading] = useState(false);
  const handleResetAll = (reset) => {
    reset();
    setAddCustomer(false);
  };
  return (
    <div className={styles.AddCustomerWrapper}>
      <Formik
        initialValues={{
          name: "",
        }}
        validationSchema={CustomerSchema}
        onSubmit={(values) => {
          setLoading(true);
          var data = JSON.stringify({
            name: values?.name,
          });

          var config = {
            method: "post",
            url: `${BASE_URL}/api/staffs`,
            headers: {
              "x-auth-token": token(),
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              message.success(response?.data?.message);
              setAddCustomer(false);
              setLoading(false);
            })
            .catch(function (error) {
              setLoading(false);
              message.error(error?.response?.data?.message);
            });
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          handleReset,
          values,
        }) => (
          <>
            <div className="p-2">
              <p className="mb-1">
                Name<span className="text-danger">*</span>
              </p>
              <Input
                placeholder="Staff name"
                onChange={handleChange("name")}
                onBlur={handleBlur("name")}
                value={values.name}
              />
              {errors.name && touched.name ? (
                <pre className="text-danger">{errors.name}</pre>
              ) : null}
            </div>
            <Divider className="mt-2" />
            <Row align="middle" justify="center">
              <Space>
                <Button
                  type="secondary"
                  icon={<CloseOutlined />}
                  size="large"
                  onClick={() => handleResetAll(handleReset)}
                  className="d-flex align-items-center"
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  size="large"
                  onClick={handleSubmit}
                  className="d-flex align-items-center"
                  loading={loading}
                >
                  Save
                </Button>
              </Space>
            </Row>
          </>
        )}
      </Formik>
    </div>
  );
}

export default AddStaff;
